import { useEffect, useState, useRef } from 'react'
import {
  Campaignservicev1Audience,
  Campaignservicev1OrderLine,
  Targetjobservicev1Audience,
  V1OrderLineStatus,
  V1State,
  V1StatsResponse,
} from 'next-gen-sdk'
import {
  Button,
  Fa,
  FormItem,
  TextInput,
  Text,
  Tooltip,
} from '@eltoro-ui/components'
import {
  ErrorMessage,
  isValidCharLength,
  formatDate,
  getTypeMoneyCost,
  getDaysBetween,
  styleTailwind,
  truncateMiddle,
  userFriendlyAudienceName,
  checkOLAudienceCount,
  validCheck,
  getHighestCPMProductType,
} from 'Helpers'
import { checkIfFullJob, getJobQuotes } from 'Requests'
import { useCampaignContext, useAppContext } from 'Contexts'
import { CreativesCardItem } from './CreativesCardItem'
import { ServeStatsTable } from 'Components/OrderlineTable/OrderlineContainer'
import { useLocation } from 'react-router-dom'
import { OrderlineStateIndicator } from 'Components/OrderlineTable/OrderlineStateIndicator'
import { OrderlinePaceIndicator } from 'Components/OrderlineTable/OrderlinePaceIndicator'
import classNames from 'classnames'
import { OLPauseNoteModal } from './OLPauseNoteModal'
import { OrderLineNotes } from 'Components/OrderlineTable/ExpansionHeader/OrderLineNotes'
import { SupportRequestModal } from 'Components/SupportRequestModal'
import dayjs from 'dayjs'
import { statMakerForEnvironment } from 'Components/Reports/Stats/statMockData'
import { InfoPopover } from 'Components'

const OLCircleButton = styleTailwind(
  Button,
  'Button--editor Button--circle clickthrough-save min-w-[34px]',
)
const OrderLineHeader = styleTailwind(
  'div',
  'OrderLineHeader flex flex-col laptop:text-l desktop:text-xl items-start gap-1 justify-start leading-none',
)
const OrderLineText = styleTailwind(
  Text,
  'OrderLineText font-bold leading-none text-m block',
)
const OrderLineTextWarn = styleTailwind(
  Text,
  'OrderLineText leading-none text-m bg-warning-200 border rounded border-transparent',
)
const OrderLineSubText = styleTailwind(
  Text,
  'OrderLineSubText font-light leading-none text-s italic',
)
const OLCardItem = styleTailwind(
  'div',
  'OLCardItem group flex flex-1 gap-1 w-auto h-full items-start justify-start leading-none',
)
const OLCardItemTT = styleTailwind(
  'div',
  'OCard__Audience--tooltip absolute text-warning right-0 group group-hover:text-warning-700',
)

export const OLCard = ({
  orderLine,
}: {
  orderLine: Campaignservicev1OrderLine
}) => {
  const [currentOrderLine, setCurrentOrderLine] = useState(orderLine)
  const [audienceQuotes, setAudienceQuotes] = useState<{
    included?: {
      count?: number
      quoteStatus?: {
        job: Campaignservicev1Audience | Targetjobservicev1Audience
        quoted: boolean
        count?: number | undefined
      }[]
    }
    excluded?: {
      count?: number
      quoteStatus?: {
        job: Campaignservicev1Audience | Targetjobservicev1Audience
        quoted: boolean
        count?: number | undefined
      }[]
    }
  }>()
  const [showPauseModal, setShowPauseModal] = useState(false)
  //Stats:
  const [olStatsData, setOlStatsData] = useState<V1StatsResponse>()
  const [editName, setEditName] = useState(false)
  const [name, setName] = useState<string>(orderLine.name || '')
  const [supportModal, setSupportModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setRefresh } = useCampaignContext()
  const { campaignServiceApi, roles, isOnHold, currentOrg } = useAppContext()
  const { pathname } = useLocation()
  const { creatives, startTime, endTime } = currentOrderLine
  const [audiences, setAudiences] = useState<
    Campaignservicev1Audience[] | undefined
  >([])
  const isMounted = useRef(false)
  useEffect(() => {
    setLoading(true)
    setAudiences(orderLine.audiences)
  }, [orderLine])
  useEffect(() => {
    if (audiences && orderLine.orgId) {
      const quoteAudiences = async () => {
        if (!orderLine.orgId) return
        const includedAudiences = audiences.filter(
          (currAud) => !currAud.exclude,
        )
        const excludedAudiences = audiences.filter((currAud) => currAud.exclude)
        const excludedJobsQuoted = await getJobQuotes(
          excludedAudiences,
          orderLine.orgId,
        )
        const includedJobsQuoted = await getJobQuotes(
          includedAudiences,
          orderLine.orgId,
        )
        setAudienceQuotes({
          included: includedJobsQuoted,
          excluded: excludedJobsQuoted,
        })
        setLoading(false)
      }
      quoteAudiences()
    }
  }, [orderLine, orderLine.orgId, audiences])

  const allIncludedQuoted = audienceQuotes?.included?.quoteStatus?.every(
    (status) => status.quoted === true,
  )
  const allExcludedQuoted = audienceQuotes?.excluded?.quoteStatus?.every(
    (status) => status.quoted === true,
  )

  const totalAudiences = allIncludedQuoted
    ? audienceQuotes?.included?.count || 0
    : 'Quoting'

  const totalExcluded = allExcludedQuoted
    ? audienceQuotes?.excluded?.count || 0
    : undefined
  // This component is used on both dashboard and campaign context,
  // and campaign context will update the orderLine prop via setRefresh.
  // This is needed to keep currentOrderLine updated
  // (on dashboard the currentOrderLine is updated from here)
  useEffect(() => {
    if (isMounted.current) {
      setCurrentOrderLine(orderLine)
    } else {
      isMounted.current = true
    }
  }, [orderLine])

  const handleSaveName = (newName: string) => {
    if (!currentOrderLine.id || !campaignServiceApi || !orderLine.orgId) return
    campaignServiceApi
      .advertisingPlatformServiceUpdateOrderLine(
        currentOrderLine.id,
        orderLine.orgId,
        {
          name: newName,
        },
        'name',
      )
      .then((updated) => {
        setCurrentOrderLine(updated)
        setEditName(false)
        setName('')
        setRefresh(true)
      })
  }

  const handlePause = (note: string) => {
    if (!campaignServiceApi || !currentOrderLine.id || !currentOrderLine.orgId)
      return
    campaignServiceApi
      .advertisingPlatformServicePauseOrderLine(currentOrderLine.id, {
        note: {
          content: `PAUSE: ${note}`,
        },
        orgId: currentOrderLine.orgId,
      })
      .then((updated) => {
        setCurrentOrderLine(updated)
        setShowPauseModal(false)
        setRefresh(true)
      })
  }

  const handlePlay = () => {
    if (!campaignServiceApi || !currentOrderLine.id || !currentOrderLine.orgId)
      return
    campaignServiceApi
      .advertisingPlatformServicePlayOrderLine(currentOrderLine.id, {
        note: {
          content: 'Played via Order Line Dashboard',
        },
        orgId: currentOrderLine.orgId,
      })
      .then((updated) => {
        setCurrentOrderLine(updated)
        setRefresh(true)
      })
  }

  const isOnDashboard = pathname.includes('campaigns/dashboard')
  const isRetargeting = !!audiences?.find(
    (aud) => aud.type === 'AUDIENCE_TYPE_RETARGETING',
  )
  const isMapPoly = !!audiences?.find(
    (aud) => aud.type === 'AUDIENCE_TYPE_IPSFORGEOJSON',
  )
  const isB2B = !!audiences?.find((aud) => aud.type === 'AUDIENCE_TYPE_B2B')
  const isB2C = !!audiences?.find((aud) => aud.type === 'AUDIENCE_TYPE_B2C')

  const isLive =
    currentOrderLine.status &&
    ([
      'ORDERLINE_STATUS_DEPLOYED',
      'ORDERLINE_STATUS_ERRORED_AND_DEPLOYED',
      'ORDERLINE_STATUS_ERRORED_AND_SERVING',
      'ORDERLINE_STATUS_SERVING',
      'ORDERLINE_STATUS_COMPLETED',
      'ORDERLINE_STATUS_ARCHIVED',
      'ORDERLINE_STATUS_PAUSED',
      'ORDERLINE_STATUS_DEPLOYING',
    ] as V1OrderLineStatus[]).includes(currentOrderLine.status)

  const showStatsAndEditing = isOnDashboard && isLive

  const {
    hasRequiredAudienceCount,
    hasAudienceWithNoMatches,
    matchWarning,
  } = checkOLAudienceCount(currentOrderLine)

  const countsByType = (c: string) => {
    const filteredJobs =
      (audienceQuotes &&
        audienceQuotes.included &&
        audienceQuotes.included.quoteStatus &&
        audienceQuotes.included?.quoteStatus.length > 0 &&
        audienceQuotes.included.quoteStatus.filter((qs) => {
          if (!qs.job.productType) return null
          return userFriendlyAudienceName(qs.job.productType) === c
        })) ||
      []
    return (
      <>
        <li>{c}</li>
        <li>
          {filteredJobs.reduce((acc, b) => {
            if (b.job && b.job.productType === 'PRODUCT_TYPE_MAP_POLYGON') {
              return filteredJobs.length
            }
            if (!checkIfFullJob(b.job)) {
              return acc + (b.job.matched || 0)
            }
            return acc + (b.count || 0)
          }, 0)}
        </li>
      </>
    )
  }

  return (
    <>
      <div className="OLCard col-span-6 flex w-full">
        <div
          className={
            'OLCard__row auto-grid-order-lines-no-checkbox relative w-full flex-1 gap-2'
          }
        >
          {/* Creatives */}
          <CreativesCardItem
            orgId={orderLine.orgId}
            creatives={orderLine.creatives}
            adType={currentOrderLine.adType}
            readyToDeploy={
              !!(
                isOnDashboard &&
                !hasAudienceWithNoMatches &&
                hasRequiredAudienceCount &&
                currentOrderLine.status &&
                currentOrderLine.status === 'ORDERLINE_STATUS_DRAFT' &&
                currentOrderLine.audiences &&
                currentOrderLine.audiences.length &&
                currentOrderLine.creatives &&
                currentOrderLine.creatives.length &&
                currentOrderLine.impressions &&
                currentOrderLine.startTime &&
                currentOrderLine.endTime &&
                dayjs(orderLine.startTime).isAfter(
                  dayjs().utc(true).startOf('day'),
                )
              )
            }
          />
          {/* Audiences */}
          <OLCardItem className="OLCard__Targets flex-col disabled:pointer-events-none disabled:opacity-50">
            <OrderLineHeader className="OLCard__Audiences-value relative w-full !gap-0">
              <>
                {(() => {
                  if (!audienceQuotes || loading)
                    return (
                      <div className="bg-gray h-[1.75rem] w-[8rem] animate-pulse rounded-lg" />
                    )
                  if (totalAudiences === 'Quoting')
                    return (
                      <span className="animate-pulse">{totalAudiences}</span>
                    )
                  if (!audiences || audiences?.length === 0)
                    return <span className="pl-4">&ndash;</span>
                  if (isRetargeting || isMapPoly)
                    return <span>{audiences.length} audiences</span>
                  if (isB2B && isB2C)
                    return (
                      <>
                        {totalAudiences.toLocaleString()}&nbsp;
                        <span className="text-m leading-none">
                          businesses &amp; households
                        </span>
                      </>
                    )
                  if (isB2B)
                    return (
                      <>
                        {totalAudiences.toLocaleString()}&nbsp;
                        <span className="text-m leading-none">
                          {totalAudiences > 1 ? 'businesses ' : 'business '}
                          matched
                        </span>
                      </>
                    )
                  return (
                    <>
                      {totalAudiences.toLocaleString()}&nbsp;
                      <span className="text-m leading-none">
                        {totalAudiences > 1 ? 'households ' : 'household '}
                        matched
                      </span>
                    </>
                  )
                })()}
                {(() => {
                  if (!audienceQuotes) return null
                  if (!allExcludedQuoted || !allIncludedQuoted)
                    return (
                      <OLCardItemTT>
                        <span
                          className="Tooltip-css-wrap"
                          data-tooltip="top"
                          aria-label="This order line has audiences attached that are still processing. Once your audience(s) are ready, you will be able to submit the order line."
                        >
                          <Fa icon="comment-exclamation" size={1} />
                        </span>
                      </OLCardItemTT>
                    )
                  if (hasAudienceWithNoMatches)
                    return (
                      <OLCardItemTT>
                        <span
                          className="Tooltip-css-wrap"
                          data-tooltip="top"
                          aria-label="One of your audiences has no matches, try removing the audience."
                        >
                          <Fa icon="comment-exclamation" size={1} />
                        </span>
                      </OLCardItemTT>
                    )
                  if (
                    matchWarning &&
                    matchWarning.length > 0 &&
                    !hasAudienceWithNoMatches
                  )
                    return (
                      <OLCardItemTT>
                        <span
                          className="Tooltip-css-wrap"
                          data-tooltip="top"
                          aria-label={matchWarning?.join(' ')}
                        >
                          <Fa icon="comment-exclamation" size={1} />
                        </span>
                      </OLCardItemTT>
                    )
                })()}
              </>
            </OrderLineHeader>
            <OrderLineText>
              {(() => {
                if (!audiences || audiences?.length === 0)
                  return (
                    <div
                      className="Audience__missing flex w-auto gap-1 pt-2 hover:cursor-pointer"
                      data-tooltip="right"
                      aria-label="You're missing audiences. Go back to add to all order lines or select checkbox of order line."
                    >
                      <Fa
                        icon="comment-exclamation scale-90"
                        size={2}
                        className="!text-warning m-0 p-0"
                      />
                      <span className="text-warning font-bold">
                        Missing audiences
                      </span>
                    </div>
                  )
                if (currentOrg) {
                  const productObject = getHighestCPMProductType(
                    orderLine,
                    currentOrg,
                  )
                  const friendlyProductNames = productObject?.products?.map(
                    (product) => product && userFriendlyAudienceName(product),
                  )
                  if (
                    productObject &&
                    productObject.products &&
                    productObject.products.length <= 1
                  ) {
                    return productObject?.highestCPM !== undefined
                      ? productObject.highestCPM.product.replace('_', ' ')
                      : productObject?.products &&
                        productObject?.products.length > 1
                      ? 'Multi Type'.toUpperCase()
                      : productObject?.products &&
                        productObject?.products[0] !== undefined &&
                        userFriendlyAudienceName(
                          productObject?.products[0],
                        ).toUpperCase()
                  }
                  return (
                    <span className="flex justify-evenly">
                      {productObject?.highestCPM !== undefined
                        ? productObject.highestCPM.product.replace('_', ' ')
                        : productObject?.products &&
                          productObject?.products.length > 1
                        ? 'Multi Type'.toUpperCase()
                        : productObject?.products &&
                          productObject?.products[0] !== undefined &&
                          userFriendlyAudienceName(
                            productObject?.products[0],
                          ).toUpperCase()}
                      {friendlyProductNames && friendlyProductNames.length > 1 && (
                        <InfoPopover
                          icon="fa-file-plus"
                          content={
                            <ul className="grid grid-cols-2 gap-2">
                              <li>
                                <strong>Type</strong>
                              </li>
                              <li>
                                <strong>Count</strong>
                              </li>
                              {friendlyProductNames?.map((c) => {
                                if (!c) return <></>
                                return countsByType(c)
                              })}
                            </ul>
                          }
                        />
                      )}
                    </span>
                  )
                }
                return 'Audiences'
              })()}
            </OrderLineText>
            {totalExcluded && totalExcluded > 0 ? (
              <OrderLineTextWarn>
                <small>
                  Excluded Audience:
                  {totalExcluded}
                </small>
              </OrderLineTextWarn>
            ) : null}
          </OLCardItem>
          {/* Budget */}
          <OLCardItem className="OLCard__Budget flex-col">
            <OrderLineHeader className="OLCard__Budget-value relative w-full !gap-0">
              {currentOrderLine.costRange?.estimate ? (
                `$${getTypeMoneyCost(
                  currentOrderLine.costRange?.estimate,
                ).toFixed(2)}`
              ) : (
                <div className="text-gray-600">$0</div>
              )}
              {(!creatives || creatives?.length === 0) && (
                <div className="OLCard__Budget--tooltip text-warning group group-hover:text-warning-700 absolute right-0">
                  <span
                    className="Tooltip-css-wrap"
                    data-tooltip="top"
                    aria-label="Attach Creative(s) to calculate and adjust the Budget."
                  >
                    <Fa icon="comment-exclamation" size={1} />
                  </span>
                </div>
              )}
            </OrderLineHeader>

            <OrderLineText>Budget</OrderLineText>
            {(orderLine.prepay || orderLine.political) && (
              <div className="text-s flex items-center gap-2 font-light">
                <em>Prepay</em>
                <span
                  aria-label="This order line is a pre-payment order line and cannot be edited after submission."
                  data-tooltip="top"
                >
                  <Fa icon="question-circle text-base-info" size={1} />
                </span>
              </div>
            )}
          </OLCardItem>
          {/* Impressions */}
          <OLCardItem className="OLCard__Impressions flex-col disabled:pointer-events-none disabled:opacity-50">
            <OrderLineHeader className="OLCard__Impressions-value">
              {currentOrderLine.impressions?.toLocaleString()}
            </OrderLineHeader>
            <OrderLineText>Impress&shy;ions</OrderLineText>
            {(() => {
              const days =
                currentOrderLine?.startTime &&
                currentOrderLine?.endTime &&
                getDaysBetween(
                  currentOrderLine.startTime,
                  currentOrderLine.endTime,
                )
              if (!currentOrderLine.impressions || !days) return
              const _imp =
                currentOrderLine.impressions / Number(totalAudiences) / days
              const visualImp = _imp < 1 ? _imp.toFixed(3) : _imp.toFixed(2)
              if (totalAudiences === undefined)
                return (
                  <div className="bg-gray mt-1 h-4 min-w-[9rem] animate-pulse rounded-lg" />
                )
              if (
                !totalAudiences ||
                !currentOrderLine?.impressions ||
                !days ||
                totalAudiences === 'Quoting'
              )
                return null
              return (
                <OrderLineSubText>{`${visualImp} impressions per target per day`}</OrderLineSubText>
              )
            })()}
          </OLCardItem>
          {/* Flight dates */}
          <OLCardItem className="OLCard__Durations relative flex-col">
            <>
              <OrderLineHeader className="OLCard__Durations-days w-11/12 !flex-row justify-between gap-1">
                {startTime && endTime ? (
                  <>
                    {getDaysBetween(startTime, endTime) === 0
                      ? '1'
                      : getDaysBetween(startTime, endTime).toLocaleString()}
                    &nbsp;
                    {getDaysBetween(startTime, endTime) === 0 ? 'Day' : 'Days'}
                  </>
                ) : (
                  <div className="text-warning flex">
                    <Fa icon="warning" size={1} />
                    Missing
                  </div>
                )}
                {dayjs(startTime).isBefore(
                  dayjs().utc(true).startOf('day'),
                ) && (
                  <div className="relative right-0">
                    <span
                      className="OLCard__Durations-past Tooltip-css-wrap pt-2"
                      data-tooltip="top"
                      aria-label="Start Date is in the past"
                    >
                      <Fa
                        icon="fa-triangle-exclamation"
                        size={1}
                        className="text-warning-500"
                      />
                    </span>
                  </div>
                )}
              </OrderLineHeader>
              <OrderLineText>Flight duration</OrderLineText>
              <OrderLineSubText>
                {currentOrderLine?.startTime &&
                  currentOrderLine?.endTime &&
                  `${formatDate(currentOrderLine.startTime)}-${formatDate(
                    currentOrderLine.endTime,
                  )}`}
              </OrderLineSubText>
            </>
          </OLCardItem>
          {/* OL Name */}
          <OLCardItem
            className={classNames('OLCard__Name w-auto', {
              'hover:cursor-pointer': !isLive,
            })}
            onClick={() => !isLive && !orderLine.locked && setEditName(true)}
            aria-hidden="true"
          >
            {!orderLine.locked && editName && !isLive && !isOnHold ? (
              <>
                <FormItem
                  wrapperClassname="OLCard__name--form-item flex flex-col"
                  htmlFor="name"
                  required
                  errorMessage={ErrorMessage({
                    fieldName: name,
                    max: 255,
                    label: 'Order Line name',
                  })}
                  valid={validCheck(name) && isValidCharLength(name, 255)}
                  counter={name.length > 0}
                  className="OLCard__name-input-container relative w-full"
                >
                  <TextInput
                    classNameWrap="OLCard__name-input-wrap"
                    placeholder={currentOrderLine.name}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value)
                    }}
                    maxLength={256}
                  />
                </FormItem>
                <OLCircleButton
                  className="ml-auto mt-2"
                  rounded
                  iconOnly={
                    <Fa icon={`${!name ? 'times' : 'save'}`} size={1} />
                  }
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation()
                    if (name) {
                      handleSaveName(name)
                      setName('')
                    }
                    setEditName(false)
                  }}
                />
              </>
            ) : (
              <>
                {currentOrderLine.status === 'ORDERLINE_STATUS_REJECTED' && (
                  <OrderLineNotes
                    className="CampaignReview__ol-notes"
                    orderLine={currentOrderLine}
                    rejected
                  />
                )}
                <div className="OrderLineName__wrap relative flex flex-col gap-3">
                  <OrderLineHeader className="!text-l break-all">
                    {truncateMiddle(currentOrderLine.name, 95) || 'No name'}
                  </OrderLineHeader>
                  <OrderLineText>Name</OrderLineText>
                </div>
                {!isLive && !orderLine.locked && !isOnHold && (
                  <div className="OrderLineName__button absolute bottom-1 right-0">
                    <span
                      className="OrderLineName__button-tooltip"
                      data-tooltip="left"
                      aria-label="Click to edit order line's name"
                    >
                      <Button
                        iconOnly={
                          <Fa icon="pencil" size={1} className="scale-75" />
                        }
                      />
                    </span>
                  </div>
                )}
              </>
            )}
          </OLCardItem>
        </div>
      </div>

      {showStatsAndEditing && (
        <div className="OLCard__showStatsAndEditing--wrap col-span-7">
          <div className="OLCard__dashboard-sub-section mt-4 flex">
            {currentOrderLine.state && (
              <div className="OLCard__status-pace-pause mr-2 mt-2 flex w-[10rem] flex-col items-center gap-4">
                {(currentOrderLine.status === 'ORDERLINE_STATUS_DEPLOYED' ||
                  currentOrderLine.status === 'ORDERLINE_STATUS_PAUSED') &&
                currentOrderLine.state === 'ORDERLINE_STATE_APPROVED' ? (
                  <OrderlineStateIndicator
                    state={
                      currentOrderLine.status === 'ORDERLINE_STATUS_PAUSED'
                        ? 'ORDERLINE_STATE_PAUSED'
                        : 'ORDERLINE_STATE_ACTIVE'
                    }
                    reason={
                      currentOrderLine.status === 'ORDERLINE_STATUS_PAUSED'
                        ? 'Order line is paused, and the start date is in the future'
                        : 'Order line has been deployed, but is not serving yet'
                    }
                  />
                ) : (
                  <OrderlineStateIndicator
                    className="whitespace-nowrap"
                    state={currentOrderLine.state}
                    reason={currentOrderLine.reason}
                  />
                )}
                {([
                  'ORDERLINE_STATE_APPROVED',
                  'ORDERLINE_STATE_ACTIVE',
                  'ORDERLINE_STATUS_PAUSED',
                ] as V1State[]).includes(currentOrderLine.state) && (
                  <>
                    {(() => {
                      if (
                        currentOrderLine.status === 'ORDERLINE_STATUS_DEPLOYING'
                      )
                        return null
                      return currentOrderLine.status ===
                        'ORDERLINE_STATUS_PAUSED' ? (
                        <Button
                          iconLeft={<Fa icon="play" size={1} />}
                          size="s"
                          className="w-max"
                          onClick={handlePlay}
                          disabled={
                            currentOrderLine.locked ||
                            (roles && roles.includes('nextgen_read_only')) ||
                            isOnHold
                          }
                        >
                          Play
                        </Button>
                      ) : (
                        <Button
                          iconLeft={<Fa icon="pause" size={1} />}
                          size="s"
                          className="w-max"
                          onClick={() => setShowPauseModal(true)}
                          disabled={
                            currentOrderLine.locked ||
                            (roles && roles.includes('nextgen_read_only')) ||
                            isOnHold
                          }
                        >
                          Pause
                        </Button>
                      )
                    })()}
                    {showPauseModal && (
                      <OLPauseNoteModal
                        handlePause={handlePause}
                        cancelFunc={setShowPauseModal}
                      />
                    )}
                    <OrderlinePaceIndicator
                      pace="not started"
                      className="gap-1"
                    />
                  </>
                )}
              </div>
            )}
            <div className="OLCard__stats-section flex w-full flex-col gap-4">
              <ServeStatsTable
                orderline={currentOrderLine}
                stats={
                  process.env.REACT_APP_ENV === 'production' &&
                  !/dev/.test(window.location.href)
                    ? olStatsData
                    : statMakerForEnvironment(orderLine)
                }
              />
              <div className="mt-3 flex justify-end gap-2">
                <Button
                  iconLeft={<Fa icon="chart-line-up" size={1} />}
                  size="s"
                >
                  Performance Report
                </Button>
                <Button
                  iconLeft={<Fa icon="question" size={1} />}
                  size="s"
                  onClick={() => setSupportModal(true)}
                >
                  Support Request
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {supportModal && orderLine && (
        <div className="OLCard__SupportRequestModal--wrap col-span-7">
          <SupportRequestModal
            handleClose={() => setSupportModal(false)}
            orderLine={orderLine}
          />
        </div>
      )}
    </>
  )
}
