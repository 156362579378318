import { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { V3RegistrationForm, Campaignservicev1OrderLine } from 'next-gen-sdk'
import { useAuth } from 'react-oidc-context'
import { useForm, useValidatedState, useValidation } from '@eltoro-ui/hooks'
import classNames from 'classnames'
import { ErrorMessage, isValidCharLength, styleTailwind } from 'Helpers'
import { PageContent } from 'Components'
import {
  Button,
  Checkbox,
  Fa,
  Form,
  FormItem,
  PreviewType,
  Text,
  TextInput,
  TextHeader,
  Uploader,
} from '@eltoro-ui/components'
import { useCampaignContext, useAppContext } from 'Contexts'
import { UserType } from 'types'
import './PoliticalTransparencyPage.scss'

const PolitInput = styleTailwind(
  TextInput,
  'PoliticalTransparencyInput relative flex items-center justify-center gap-2',
)
const PolSelect = styleTailwind(
  'select',
  'PoliticalTransparencySelect Select border-thin border-grey-200 disabled:hover:border-grey-200 hover:border-grey-600 focus:bg-grey-050 relative flex items-center justify-center gap-2 rounded-lg py-2 px-4 transition-all disabled:bg-transparent',
)
const PolSubTitle = styleTailwind(
  TextHeader,
  'TransparencyInfoInput__subTitle mb-2 text-l',
)
const PolInputContainer = styleTailwind(
  'div',
  'Transparency-InputContainer gap-y-2 gap-x-3',
)

const usStates = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]
const provinceOptions = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'YT', label: 'Yukon' },
]
const countryOptions = [
  {
    label: 'Canada',
    value: 'Canada',
  },
  {
    label: 'United States',
    value: 'United States of America',
  },
]
export type TransparencyInfoType = {
  candidate: string
  electionDate: Date
  submitterName: string
  submitterAddress: string
  submitterCity: string
  submitterState: string
  submitterZip: string
  submitterPhone: string
  sponsorName: string
  treasurerName: string
  sponsorAddress: string
  sponsorCity: string
  sponsorState: string
  sponsorZip: string
  sponsorPhone: string
  payer: string
  paymentMethod: string
  paymentOther: string
  AudiencesTargeted: string
  AudienceDesc: string
  fecID: string
  independentCommittee: boolean
  stateRegistrationForm: V3RegistrationForm
  isAccuracyAcknowledged: boolean
}
type ParamType = {
  campaignId: string
}

export const PoliticalTransparencyPage = ({
  navFunc,
}: {
  navFunc: (step: 1 | 2 | 3 | 4 | 5) => void
}) => {
  const { campaignServiceApi, currentOrg } = useAppContext()
  const {
    campaign,
    orderLines,
    fetchDraftCampaign,
    start_date,
    end_date,
    setRefresh,
  } = useCampaignContext()
  const auth = useAuth()
  const loc = useLocation()
  const params = useParams<ParamType>()
  const history = useNavigate()
  const [user, SetUser] = useState<UserType | undefined>()
  const [uncheckedData, setUncheckedData] = useState({
    candidate: '',
    electionDate: '',
    payer: '',
    paymentMethod: '',
    paymentOther: '',
    submitterName: '',
    treasurerName: '',
    submitterAddress: '',
    submitterCity: '',
    submitterState: '',
    submitterZip: '',
    sponsorName: '',
    sponsorAddress: '',
    sponsorCity: '',
    submitterCountry: '',
    sponsorState: '',
    sponsorZip: '',
    audiencesTargeted: '',
    audienceDesc: '',
    fecID: '',
    stateRegistrationForm: { filePath: '' } as V3RegistrationForm,
    independentCommittee: false,
    isAccuracyAcknowledged: false,
  })
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const [
    submitterZipcode,
    setSubmitterZipcode,
    isValidSubmitterZipcode,
  ] = useValidatedState('', /^\d{5}$/)
  const isValidCanadianZip = useValidation('canadian-zip')

  const [
    sponsorZipcode,
    setSponsorZipcode,
    isValidSponsorZipcode,
  ] = useValidatedState('', /^\d{5}$/)

  const [
    sponsorPhone,
    setSponsorPhone,
    isValidSponsorPhone,
  ] = useValidatedState(
    '',
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  )
  const [
    submitterPhone,
    setSubmitterPhone,
    isValidSubmitterPhone,
  ] = useValidatedState(
    '',
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  )

  const {
    candidate,
    electionDate,
    payer,
    paymentMethod,
    paymentOther,
    submitterName,
    submitterAddress,
    submitterCity,
    submitterState,
    submitterCountry,
    sponsorName,
    treasurerName,
    sponsorAddress,
    sponsorCity,
    sponsorState,
    audienceDesc,
    audiencesTargeted,
    fecID,
    independentCommittee,
    stateRegistrationForm,
    isAccuracyAcknowledged,
  } = uncheckedData

  const { formData, isValidForm, touched } = useForm({
    candidate,
    electionDate,
    payer,
    paymentMethod,
    paymentOther,
    submitterName,
    submitterAddress,
    submitterCity,
    submitterState,
    submitterCountry,
    submitterPhone,
    sponsorName,
    treasurerName,
    sponsorAddress,
    sponsorCity,
    sponsorState,
    sponsorPhone,
    audienceDesc,
    audiencesTargeted,
    fecID,
    independentCommittee,
    stateRegistrationForm,
    submitterZipcode,
    sponsorZipcode,
    isAccuracyAcknowledged,
  })
  const required = [
    `candidate`,
    `payer`,
    `paymentMethod`,
    `sponsorName`,
    `treasurerName`,
    `sponsorAddress`,
    `sponsorCity`,
    `sponsorState`,
    `sponsorZipcode`,
    `sponsorPhone`,
    `submitterName`,
    `submitterAddress`,
    `submitterCity`,
    `submitterState`,
    `submitterZipcode`,
    'submitterCountry',
    'submitterPhone',
    `audienceDesc`,
    `audiencesTargeted`,
    `fecID`,
    `stateRegistrationForm`,
    `isAccuracyAcknowledged`,
  ]
  const { validForm, missingFields } = isValidForm([
    ...required,
    isValidSponsorZipcode,
    isValidSponsorPhone,
    isValidSubmitterPhone,
    submitterCountry !== 'Canada'
      ? isValidSubmitterZipcode
      : isValidCanadianZip(submitterZipcode),
    isValidCharLength(candidate, 255),
    isValidCharLength(sponsorName, 100),
    isValidCharLength(treasurerName, 100),
    isValidCharLength(sponsorAddress, 255),
    isValidCharLength(sponsorCity, 100),
    isValidCharLength(sponsorZipcode, 5),
    isValidCharLength(sponsorPhone, 10),
    isValidCharLength(submitterAddress, 255),
    isValidCharLength(submitterCity, 100),
    submitterCountry !== 'Canada'
      ? isValidCharLength(submitterZipcode, 5)
      : isValidCharLength(submitterZipcode, 6),
    isValidCharLength(fecID, 50),
  ])
  //refetches from url when page is reloaded and state resets
  if (params.campaignId && !campaign?.id && !loc.pathname.includes('edit')) {
    fetchDraftCampaign(params.campaignId)
  }

  useEffect(() => {
    const getUser = () => {
      SetUser(auth.user?.profile)
    }
    getUser()
  }, [])

  const validCheck = (field: string, charLengthCheck = true) => {
    if (attemptedSubmit || (field !== '' && field.length < 2)) {
      if (field && charLengthCheck) {
        return true
      }
      return false
    }
    return true
  }

  const isReadOnly =
    campaign?.politicalTransparency &&
    orderLines?.[0]?.politicalFields &&
    orderLines.every((ol) => ol.status === 'ORDERLINE_STATUS_REVIEW_PENDING')

  useEffect(() => {
    const campaignFields = campaign?.politicalTransparency
    const olFields = orderLines?.[0]?.politicalFields
    setUncheckedData({
      candidate: campaignFields?.politicalObjective || '',
      electionDate: campaignFields?.electionDates || '',
      payer: olFields?.paidForBy || '',
      paymentMethod:
        campaignFields?.paymentMethodType || 'Credit or debit card',
      paymentOther: campaignFields?.paymentMethodOther || '',
      submitterName:
        olFields?.submitterName ||
        `${user?.given_name || ''} ${user?.family_name || ''}`,
      submitterAddress: olFields?.submitterAddress || '',
      submitterCity: olFields?.submitterCity || '',
      submitterState: olFields?.submitterState || '',
      submitterZip: olFields?.submitterZip || '',
      submitterCountry: olFields?.submitterCountry || '',
      sponsorName: campaignFields?.orgName || '',
      treasurerName: campaignFields?.treasurerName || '',
      sponsorAddress: campaignFields?.orgAddress1 || '',
      sponsorCity: campaignFields?.orgCity || '',
      sponsorState: campaignFields?.orgRegion || '',
      sponsorZip: campaignFields?.orgPostalCode || '',
      audiencesTargeted: olFields?.audienceIdsTargeted || '',
      audienceDesc: olFields?.audienceDescription || '',
      isAccuracyAcknowledged: campaignFields?.isAccuracyAcknowledged || false,
      fecID: campaignFields?.usfecid || '',
      independentCommittee: campaignFields?.isExpenditureCommittee || false,
      stateRegistrationForm: campaignFields?.registrationForm || {
        filePath: '',
      },
    })
    setSubmitterPhone(olFields?.submitterPhone || '')
    setSponsorPhone(campaignFields?.orgPhone || '')
    setSponsorZipcode(campaignFields?.orgPostalCode || '')
    setSubmitterZipcode(olFields?.submitterZip || '')
  }, [
    campaign?.id,
    campaign?.politicalTransparency,
    orderLines,
    setSponsorPhone,
    setSponsorZipcode,
    setSubmitterZipcode,
    setSubmitterPhone,
    user?.given_name,
    user?.family_name,
  ])

  const onSubmit = async () => {
    if (currentOrg?.id && campaign?.id && campaignServiceApi) {
      // Use new `SetCampaignPoliticalForm` WIP
      // await campaignServiceApi.advertisingPlatformServiceSetCampaignPoliticalForm(
      //   campaign.id,
      //   {
      //     orgId: currentOrg.id,
      //     politicalTransparency: {
      //       electionDates: formData?.electionDate,
      //       isAccuracyAcknowledged:
      //         uncheckedData?.isAccuracyAcknowledged || false,
      //       isExpenditureCommittee: formData?.independentCommittee,
      //       orgAddress1: formData?.sponsorAddress,
      //       orgCity: formData?.sponsorCity,
      //       orgCountry: 'United States',
      //       orgName: formData?.sponsorName,
      //       orgPhone: formData?.sponsorPhone,
      //       orgPhoneCode: '+1(United States)',
      //       orgPostalCode: formData?.sponsorZipcode,
      //       orgRegion: formData?.sponsorState,
      //       paymentMethodOther: formData?.paymentOther,
      //       paymentMethodType: formData?.paymentMethod,
      //       politicalObjective: formData?.candidate,
      //       registrationForm: { filePath: '' },
      //       treasurerName: formData?.treasurerName,
      //       usfecid: formData?.fecID,
      //     },
      //     politicalFields: {
      //       audienceDescription: formData.audienceDesc,
      //       audienceIdsTargeted: formData.audiencesTargeted,
      //       orgZip: formData.sponsorZipcode,
      //       paidForBy: formData.payer,
      //       submitterName: formData.submitterName,
      //       submitterAddress: formData.submitterAddress,
      //       submitterCity: formData.submitterCity,
      //       submitterState: formData.submitterState,
      //       submitterZip: formData.submitterZipcode,
      //       submitterCountry: formData.submitterCountry,
      //     },
      //   },
      // )
      // update the campaign fields
      await campaignServiceApi.advertisingPlatformServiceUpdateCampaign(
        campaign.id,
        currentOrg.id,
        {
          politicalTransparency: {
            electionDates: formData?.electionDate,
            isAccuracyAcknowledged:
              uncheckedData?.isAccuracyAcknowledged || false,
            isExpenditureCommittee: formData?.independentCommittee,
            orgAddress1: formData?.sponsorAddress,
            orgCity: formData?.sponsorCity,
            orgCountry: 'United States',
            orgName: formData?.sponsorName,
            orgPhone: formData?.sponsorPhone,
            orgPhoneCode: '+1(United States)',
            orgPostalCode: formData?.sponsorZipcode,
            orgRegion: formData?.sponsorState,
            paymentMethodOther: formData?.paymentOther,
            paymentMethodType: formData?.paymentMethod,
            politicalObjective: formData?.candidate,
            registrationForm: { filePath: '' },
            treasurerName: formData?.treasurerName,
            usfecid: formData?.fecID,
          },
        },
      )
      if (orderLines && orderLines.length === 0) {
        await campaignServiceApi
          .advertisingPlatformServiceCreateOrderLine(
            currentOrg?.id,
            {
              name: `${campaign?.name}_Draft_${1}`,
              startTime: new Date(start_date),
              endTime: new Date(end_date),
              impressions: currentOrg?.minimumImpressions,
              political: true,
              politicalFields: {
                audienceDescription: formData.audienceDesc,
                audienceIdsTargeted: formData.audiencesTargeted,
                orgZip: formData.sponsorZipcode,
                paidForBy: formData.payer,
                submitterName: formData.submitterName,
                submitterAddress: formData.submitterAddress,
                submitterCity: formData.submitterCity,
                submitterState: formData.submitterState,
                submitterZip: formData.submitterZipcode,
                submitterCountry: formData.submitterCountry,
                submitterPhone: formData.submitterPhone,
              },
            },
            campaign?.id,
          )
          .then((res) => {
            if (!res?.campaign?.id) return
            const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''
            history(
              `../${loc.pathname.includes('edit') ? 'edit' : 'create'}/${
                res.campaign?.id
              }/settings${query}`,
            )
            navFunc(4)
            fetchDraftCampaign(res.campaign?.id)
          })
        return
      }
      // update the order line fields
      orderLines?.reduce(
        async (
          previous: Promise<Campaignservicev1OrderLine | undefined | void>,
          ol,
        ) => {
          if (!currentOrg?.id || !ol.id || !campaignServiceApi) return
          await previous

          return campaignServiceApi
            .advertisingPlatformServiceUpdateOrderLine(ol.id, currentOrg.id, {
              politicalFields: {
                audienceDescription: formData.audienceDesc,
                audienceIdsTargeted: formData.audiencesTargeted,
                orgZip: formData.sponsorZipcode,
                paidForBy: formData.payer,
                submitterName: formData.submitterName,
                submitterAddress: formData.submitterAddress,
                submitterCity: formData.submitterCity,
                submitterState: formData.submitterState,
                submitterCountry: formData.submitterCountry,
                submitterZip: formData.submitterZipcode,
                submitterPhone: formData.submitterPhone,
              },
            })
            .then((res) => {
              setRefresh(true)
              return res
            })
        },
        Promise.resolve(),
      )
      const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''
      history(
        `../${loc.pathname.includes('edit') ? 'edit' : 'create'}/${
          campaign?.id
        }/settings${query}`,
      )
      navFunc(4)
      fetchDraftCampaign(campaign?.id)
    }
  }

  return (
    <PageContent className="TransparencyContainer flex items-center">
      <div className="TransparencyInfoInput m-auto">
        {!validForm && missingFields.length > 0 && attemptedSubmit && (
          <div className="TransparencyPrompt bg-warning-100 animate-slidedown relative z-30 -mx-6 mb-6 -mt-2 flex items-center justify-center gap-x-3 p-3">
            <Fa
              className="text-danger-400"
              icon="circle-exclamation"
              size={4}
            />
            <Text className="text-warning-600 text-xl">
              Please fill out all required
              <span className="text-danger-600">* </span>fields in the form
              below
            </Text>
          </div>
        )}
        <Form
          className={`PoliticalTransparencyForm flex flex-col ${
            !validForm && missingFields.length > 0 && attemptedSubmit
              ? 'PoliticalTransparencyForm__error'
              : ''
          }`}
          onSubmit={onSubmit}
          required={required}
          valid={validForm}
          touched={touched}
          missingFields={missingFields}
        >
          <div className="Transparency_subHeader-container bg-primary-100 border-t-thin border-tint-gray-300 border-r-thin border-l-thin flex flex-col rounded-t-md p-7">
            <TextHeader className="mb-2 text-3xl" type={3}>
              Transparency form
            </TextHeader>
            <Text
              className="Transparency_subHeader-text my-0 flex flex-col gap-1 py-0 text-lg"
              tag="p"
            >
              To ensure compliance with federal and state laws regarding record
              keeping and reporting requirements, as well as the policies of our
              upstream partners, El Toro requires the following information from
              you before you submit your election campaign advertisements.
              <br />
              <strong>Instructions:</strong> You must accurately complete each
              field. If a field is not applicable, please indicate “N/A” in the
              space provided. Failure to complete each field with accurate
              information is a violation of El Toro’s terms of service and may
              otherwise violate local, state, or federal law.
              <span className="font-semibold">
                Information cannot be changed after you submit the campaign.
                Please review and confirm the information is correct.
              </span>
            </Text>
          </div>
          <div className="Transparency__formFields-wrap border-r-thin border-l-thin border-b-thin border-tint-gray-300 flex flex-col gap-y-4 rounded-b-md pt-2 pb-10">
            <PolInputContainer className="CandidateRow">
              <PolSubTitle>
                The candidate or ballot initiative that is supported or opposed
              </PolSubTitle>
              <FormItem
                required
                className="col-half"
                htmlFor="candidate"
                label="Name of each candidate and ballot measure:"
                errorMessage={ErrorMessage({
                  fieldName: candidate,
                  max: 255,
                  label: "Candidate's name or Ballot initiative",
                })}
                valid={
                  validCheck(candidate) && isValidCharLength(candidate, 255)
                }
                counter={candidate.length > 0}
              >
                <PolitInput
                  name="candidate"
                  disabled={isReadOnly}
                  value={candidate}
                  maxLength={256}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUncheckedData({
                      ...uncheckedData,
                      candidate: e.target.value,
                    })
                  }}
                />
              </FormItem>
              <FormItem
                className="col-half"
                htmlFor="electionDate"
                label="Election Date"
              >
                <PolitInput
                  name="electionDate"
                  className="electionDate justify-start"
                  disabled={isReadOnly}
                  type="date"
                  value={electionDate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUncheckedData({
                      ...uncheckedData,
                      electionDate: e.target.value,
                    })
                  }
                />
              </FormItem>
            </PolInputContainer>
            {/* ------------------------------------------------------------------------------------------------------------ */}
            {/* Sponsor INFO */}
            {/* ------------------------------------------------------------------------------------------------------------ */}

            <PolInputContainer className="SponsorRow">
              <PolSubTitle>
                The name and address of the person(s)/organization who sponsored
                the advertising
              </PolSubTitle>
              <FormItem
                required
                className="col-half"
                htmlFor="sponsorName"
                label="Name"
                errorMessage={ErrorMessage({
                  fieldName: sponsorName,
                  max: 100,
                  label: "Sponsor's name",
                })}
                valid={
                  validCheck(sponsorName) && isValidCharLength(sponsorName, 100)
                }
                counter={sponsorName.length > 0}
              >
                <PolitInput
                  disabled={isReadOnly}
                  value={sponsorName}
                  maxLength={101}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUncheckedData({
                      ...uncheckedData,
                      sponsorName: e.target.value,
                    })
                  }}
                />
              </FormItem>
              <FormItem
                required
                className="col-half"
                htmlFor="treasurerName"
                label="Treasurer"
                errorMessage={ErrorMessage({
                  fieldName: treasurerName || '',
                  max: 100,
                  label: "Treasurer's name",
                })}
                valid={
                  validCheck(treasurerName) &&
                  isValidCharLength(treasurerName, 100)
                }
                counter={treasurerName.length > 0}
              >
                <PolitInput
                  name="treasurerName"
                  disabled={isReadOnly}
                  value={treasurerName}
                  maxLength={101}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUncheckedData({
                      ...uncheckedData,
                      treasurerName: e.target.value,
                    })
                  }}
                />
              </FormItem>

              <FormItem
                required
                className="col-half"
                htmlFor="sponsorAddress"
                label="Address"
                errorMessage={ErrorMessage({
                  fieldName: sponsorAddress || '',
                  max: 255,
                  label: "Sponsor's address",
                })}
                valid={
                  validCheck(sponsorAddress) &&
                  isValidCharLength(sponsorAddress, 255)
                }
                counter={sponsorAddress.length > 0}
              >
                <PolitInput
                  name="sponsorAddress"
                  disabled={isReadOnly}
                  value={sponsorAddress}
                  maxLength={256}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUncheckedData({
                      ...uncheckedData,
                      sponsorAddress: e.target.value,
                    })
                  }}
                />
              </FormItem>

              <FormItem
                required
                className="col-half"
                htmlFor="sponsorCity"
                label="City"
                errorMessage={ErrorMessage({
                  fieldName: sponsorCity || '',
                  max: 100,
                  label: "Sponsor's city",
                  isValid: isValidCharLength(sponsorCity, 100),
                })}
                valid={
                  validCheck(sponsorCity) && isValidCharLength(sponsorCity, 100)
                }
                counter={sponsorCity.length > 0}
              >
                <PolitInput
                  name="sponsorCity"
                  disabled={isReadOnly}
                  maxLength={101}
                  value={sponsorCity}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUncheckedData({
                      ...uncheckedData,
                      sponsorCity: e.target.value,
                    })
                  }}
                />
              </FormItem>

              <FormItem
                required
                className="col-quarter SelectWrap"
                htmlFor="sponsorState"
                label="State"
                errorMessage="Please select a state"
                valid={validCheck(sponsorState)}
              >
                <PolSelect
                  name="sponsorState"
                  className="bg-base"
                  disabled={isReadOnly}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setUncheckedData({
                      ...uncheckedData,
                      sponsorState: e.target.value,
                    })
                  }
                >
                  <option value="">{sponsorState}</option>
                  {usStates.map((state: string, index: number) => {
                    return (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    )
                  })}
                </PolSelect>
              </FormItem>

              <FormItem
                required
                className="col-quarter zipcode"
                htmlFor="sponsorZipcode"
                label="ZIP code"
                errorMessage={ErrorMessage({
                  fieldName: sponsorZipcode,
                  max: 5,
                  label: "Sponsor's ZIP code",
                  isValid: isValidSponsorZipcode,
                })}
                valid={
                  validCheck(sponsorZipcode) &&
                  isValidCharLength(sponsorZipcode, 5) &&
                  isValidSponsorZipcode
                }
                counter={sponsorZipcode.length > 0}
              >
                <PolitInput
                  name="sponsorZipcode"
                  disabled={isReadOnly}
                  maxLength={6}
                  // safari handles number as alphanumerical field change to tel so the FormItem's handler will prevent alpha chars.
                  type="tel"
                  value={sponsorZipcode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSponsorZipcode(e.target.value)
                  }}
                />
              </FormItem>
              <FormItem
                required
                className="col-half"
                htmlFor="sponsorPhone"
                label="Phone"
                errorMessage={ErrorMessage({
                  fieldName: sponsorPhone || '',
                  max: 10,
                  label: "Sponsor's phone number",
                  isValid: isValidSponsorPhone,
                })}
                valid={
                  validCheck(sponsorPhone) &&
                  isValidCharLength(sponsorPhone, 10) &&
                  isValidSponsorPhone
                }
                counter={sponsorPhone.length > 0}
              >
                <PolitInput
                  name="sponsorPhone"
                  disabled={isReadOnly}
                  maxLength={11}
                  type="tel"
                  value={sponsorPhone}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSponsorPhone(e.target.value)
                  }}
                />
              </FormItem>
            </PolInputContainer>
            {/* ------------------------------------------------------------------------------------------------------------ */}
            {/* SUBMITTER INFO */}
            {/* ------------------------------------------------------------------------------------------------------------ */}

            <PolInputContainer className="SubmitterRow">
              <PolSubTitle>
                The name and address of person submitting the campaign
              </PolSubTitle>
              <FormItem
                required
                className="col-half"
                htmlFor="submitterName"
                label="Name"
                errorMessage={ErrorMessage({
                  fieldName: submitterName || '',
                  max: 255,
                  label: "Submitter's name",
                })}
                valid={validCheck(submitterName)}
                counter={submitterName.length > 0}
              >
                <PolitInput
                  name="submitterName"
                  value={submitterName}
                  maxLength={256}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUncheckedData({
                      ...uncheckedData,
                      submitterName: e.target.value,
                    })
                  }}
                />
              </FormItem>

              <FormItem
                required
                className="col-half"
                htmlFor="submitterAddress"
                label="Address"
                errorMessage={ErrorMessage({
                  fieldName: submitterAddress || '',
                  max: 255,
                  label: "Submitter's address",
                })}
                valid={
                  validCheck(submitterAddress) &&
                  isValidCharLength(submitterAddress, 255)
                }
                counter={submitterAddress.length > 0}
              >
                <PolitInput
                  name="submitterAddress"
                  disabled={isReadOnly}
                  value={submitterAddress}
                  maxLength={256}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUncheckedData({
                      ...uncheckedData,
                      submitterAddress: e.target.value,
                    })
                  }
                />
              </FormItem>
              <FormItem
                required
                className="col-half"
                htmlFor="submitterCity"
                label="City"
                errorMessage={ErrorMessage({
                  fieldName: submitterCity || '',
                  max: 100,
                  label: "Submitter's city",
                })}
                valid={
                  validCheck(submitterCity) &&
                  isValidCharLength(submitterCity, 100)
                }
                counter={submitterCity.length > 0}
              >
                <PolitInput
                  name="submitterCity"
                  disabled={isReadOnly}
                  maxLength={101}
                  value={submitterCity}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUncheckedData({
                      ...uncheckedData,
                      submitterCity: e.target.value,
                    })
                  }
                />
              </FormItem>

              {submitterCountry !== 'Canada' ? (
                <FormItem
                  required
                  className="col-quarter SelectWrap"
                  htmlFor="submitterState"
                  label="State"
                  errorMessage="Please select a state"
                  valid={validCheck(submitterState)}
                >
                  <PolSelect
                    name="submitterState"
                    className="bg-base"
                    disabled={isReadOnly}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setUncheckedData({
                        ...uncheckedData,
                        submitterState: e.target.value,
                      })
                    }}
                  >
                    <option value="">{submitterState}</option>
                    {usStates.map((state: string, index: number) => {
                      return (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      )
                    })}
                  </PolSelect>
                </FormItem>
              ) : (
                <FormItem
                  required
                  className="col-quarter SelectWrap"
                  htmlFor="submitterState"
                  label="Province"
                  errorMessage="Please select a province"
                  valid={validCheck(submitterState)}
                >
                  <PolSelect
                    name="submitterState"
                    className="bg-base"
                    disabled={isReadOnly}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setUncheckedData({
                        ...uncheckedData,
                        submitterState: e.target.value,
                      })
                    }}
                  >
                    <option value="">{submitterState}</option>
                    {provinceOptions.map(
                      (
                        province: { label: string; value: string },
                        index: number,
                      ) => {
                        return (
                          <option key={index} value={province.value}>
                            {province.value}
                          </option>
                        )
                      },
                    )}
                  </PolSelect>
                </FormItem>
              )}

              {submitterCountry !== 'Canada' ? (
                <FormItem
                  required
                  className="col-quarter zipcode"
                  htmlFor="submitterZipcode"
                  label="ZIP code"
                  errorMessage={ErrorMessage({
                    fieldName: submitterZipcode || '',
                    max: 5,
                    label: "Submitter's ZIP code",
                    isValid: isValidSubmitterZipcode,
                  })}
                  valid={
                    validCheck(submitterZipcode) &&
                    isValidCharLength(submitterZipcode, 5) &&
                    isValidSubmitterZipcode
                  }
                  counter={submitterZipcode.length > 0}
                >
                  <PolitInput
                    name="submitterZipcode"
                    disabled={isReadOnly}
                    value={submitterZipcode}
                    // safari handles number as alphanumerical field change to tel so the FormItem's handler will prevent alpha chars.
                    type="tel"
                    maxLength={6}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSubmitterZipcode(e.target.value)
                    }
                  />
                </FormItem>
              ) : (
                <FormItem
                  required
                  className="col-quarter zipcode"
                  htmlFor="submitterZipcode"
                  label="Postal code"
                  errorMessage={ErrorMessage({
                    fieldName: submitterZipcode || '',
                    max: 6,
                    label: "Submitter's Postal code",
                    isValid: isValidCanadianZip(submitterZipcode),
                  })}
                  valid={
                    validCheck(submitterZipcode) &&
                    isValidCharLength(submitterZipcode, 6) &&
                    isValidCanadianZip(submitterZipcode)
                  }
                  counter={submitterZipcode.length > 0}
                >
                  <PolitInput
                    name="submitterZipcode"
                    disabled={isReadOnly}
                    value={submitterZipcode}
                    // safari handles number as alphanumerical field change to tel so the FormItem's handler will prevent alpha chars.
                    type="text"
                    maxLength={7}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSubmitterZipcode(e.target.value)
                    }
                  />
                </FormItem>
              )}
              <FormItem
                required
                className="col-half SelectWrap"
                htmlFor="submitterCountry"
                label="Country"
                errorMessage="Please select a country"
                valid={validCheck(submitterCountry)}
              >
                <PolSelect
                  name="submitterCountry"
                  className="bg-base"
                  disabled={isReadOnly}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setUncheckedData({
                      ...uncheckedData,
                      submitterCountry: e.target.value,
                    })
                  }}
                >
                  <option value="">{submitterCountry}</option>
                  {countryOptions.map((country, index: number) => {
                    return (
                      <option key={index} value={country.value}>
                        {country.label}
                      </option>
                    )
                  })}
                </PolSelect>
              </FormItem>
              <FormItem
                required
                className="col-half"
                htmlFor="submitterPhone"
                label="Phone"
                errorMessage={ErrorMessage({
                  fieldName: submitterPhone || '',
                  max: 10,
                  label: "Submitter's phone number",
                  isValid: isValidSubmitterPhone,
                })}
                valid={
                  validCheck(submitterPhone) &&
                  isValidCharLength(submitterPhone, 10) &&
                  isValidSubmitterPhone
                }
                counter={submitterPhone.length > 0}
              >
                <PolitInput
                  name="submitterPhone"
                  disabled={isReadOnly}
                  maxLength={11}
                  type="tel"
                  value={submitterPhone}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSubmitterPhone(e.target.value)
                  }}
                />
              </FormItem>
            </PolInputContainer>
            {/* ------------------------------------------------------------------------------------------------------------ */}
            {/* COST INFO */}
            {/* ------------------------------------------------------------------------------------------------------------ */}

            <PolInputContainer className="CostRow">
              <PolSubTitle>Payment information</PolSubTitle>
              <FormItem
                required
                className="col-half"
                htmlFor="payer"
                label="Who made payment"
                errorMessage={ErrorMessage({
                  fieldName: payer,
                  max: 100,
                  label: "Payer's name",
                })}
                valid={validCheck(payer) && isValidCharLength(payer, 100)}
                counter={payer.length > 0}
              >
                <PolitInput
                  name="payer"
                  disabled={isReadOnly}
                  value={payer}
                  maxLength={101}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUncheckedData({
                      ...uncheckedData,
                      payer: e.target.value,
                    })
                  }
                />
              </FormItem>

              <FormItem
                required
                className="col-half SelectWrap"
                htmlFor="paymentMethod"
                label="Method of payment"
                errorMessage="Please select method of payment used"
                valid={validCheck(paymentMethod)}
              >
                <PolSelect
                  name="paymentMethod"
                  className="bg-base PaymentMethod__select"
                  disabled={isReadOnly}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setUncheckedData({
                      ...uncheckedData,
                      paymentMethod: e.target.value,
                    })
                  }
                >
                  <option value="">{paymentMethod}</option>
                  {['Direct Debit', 'Credit Card'].map(
                    (str: string, index: number) => {
                      return (
                        <option key={index} value={str}>
                          {str}
                        </option>
                      )
                    },
                  )}
                </PolSelect>
              </FormItem>
              {/* This is not being used, but leaving commented for possible future use */}
              {/* {(uncheckedData?.paymentMethod === 'Other' ||
                formData?.paymentMethod === 'Other') && (
                <FormItem
                  required
                  className="col-half SelectWrap"
                  htmlFor="paymentOther"
                  label="Please specify"
                  errorMessage="Please specify the payment method"
                  valid={validCheck(paymentOther)}
                >
                  <PolitInput
                    classNameWrap={
                      paymentOther.length < 2 && attemptedSubmit
                        ? 'animate-glowwarning'
                        : ''
                    }
                    className={
                      paymentOther.length < 2 && attemptedSubmit
                        ? 'border-warning border-default'
                        : ''
                    }
                    disabled={isReadOnly}
                    value={paymentOther || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUncheckedData({
                        ...uncheckedData,
                        paymentOther: e.target.value,
                      })
                    }
                  />
                </FormItem>
              )} */}
            </PolInputContainer>
            {/* ------------------------------------------------------------------------------------------------------------ */}
            {/* AUDIENCE INFO */}
            {/* ------------------------------------------------------------------------------------------------------------ */}

            <PolInputContainer className="AudienceRow">
              <PolSubTitle>
                An approximate description of the geographic locations and
                audiences to reach
              </PolSubTitle>
              <FormItem
                required
                className="col-half"
                htmlFor="audiencesTargeted"
                label="Targeted audience demographics:"
                errorMessage={ErrorMessage({
                  fieldName: audiencesTargeted,
                  max: 255,
                  label: 'Targeted audience demographics:',
                })}
                valid={
                  validCheck(audiencesTargeted) &&
                  isValidCharLength(audiencesTargeted, 255)
                }
                counter={audiencesTargeted.length > 0}
              >
                <PolitInput
                  name="audiencesTargeted"
                  disabled={isReadOnly}
                  value={audiencesTargeted}
                  maxLength={256}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUncheckedData({
                      ...uncheckedData,
                      audiencesTargeted: e.target.value,
                    })
                  }
                />
              </FormItem>
              <FormItem
                required
                className="col-half"
                htmlFor="audienceDesc"
                label="Geographic location(s) targeted"
                errorMessage={ErrorMessage({
                  fieldName: audienceDesc,
                  max: 255,
                  label: 'Geographic location(s) targeted',
                })}
                valid={
                  validCheck(audienceDesc) &&
                  isValidCharLength(audienceDesc, 255)
                }
                counter={audienceDesc.length > 0}
              >
                <PolitInput
                  disabled={isReadOnly}
                  value={audienceDesc}
                  maxLength={256}
                  name="audienceDesc"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUncheckedData({
                      ...uncheckedData,
                      audienceDesc: e.target.value,
                    })
                  }
                />
              </FormItem>
            </PolInputContainer>
            {/* ------------------------------------------------------------------------------------------------------------ */}
            {/* ADDITIONAL INFO */}
            {/* ------------------------------------------------------------------------------------------------------------ */}

            <PolInputContainer className="AddInfoRow">
              <PolSubTitle>Additional Info</PolSubTitle>
              <small>
                FEC or State Election Registration number required; if Sponsor
                has not been issued an FEC or state election registration
                number, please provide FEIN or driver’s license number
              </small>
              <FormItem
                className="col-half"
                required
                htmlFor="fecID"
                label="Government ID Number"
                errorMessage={ErrorMessage({
                  fieldName: fecID,
                  max: 255,
                  label: 'FEC ID',
                })}
                valid={validCheck(fecID) && isValidCharLength(fecID, 50)}
                counter={fecID.length > 0}
              >
                <PolitInput
                  name="fecID"
                  disabled={isReadOnly}
                  value={fecID}
                  maxLength={51}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUncheckedData({
                      ...uncheckedData,
                      fecID: e.target.value,
                    })
                  }
                />
              </FormItem>

              <FormItem
                htmlFor="IndependentCommittee"
                className="Checkbox__fecID--wrap"
              >
                <Checkbox
                  disabled={isReadOnly}
                  className="Checkbox__fecID--wrap-checkbox flex h-full items-center"
                  checked={independentCommittee}
                  onChange={() =>
                    setUncheckedData({
                      ...uncheckedData,
                      independentCommittee: !independentCommittee,
                    })
                  }
                  label={
                    <Text className="whitespace-nowrap text-sm">
                      Political Independent Expenditure Committee
                    </Text>
                  }
                />
              </FormItem>
            </PolInputContainer>
            <Uploader
              className={classNames('PoliticalTransparency__uploader', {
                'pointer-events-none mt-4 opacity-25': isReadOnly,
              })}
              onDrop={(files: PreviewType[]) => {
                // this still needs an api endpoint to upload the form to s3
                console.log(files)
              }}
            >
              <Text
                tag="p"
                className="border-tint-gray-200 border-b-thin w-128 mb-1 pt-4 pb-2 text-center text-xl font-normal uppercase"
              >
                New York and New Jersey Sponsors
              </Text>
              <Text tag="p" className="text-center text-lg font-normal">
                If your advertisement relates to a state, county, or local
                election, state law requires El Toro to maintain a copy of your
                state election registration. Please upload registration here.
              </Text>
              <Text
                tag="h4"
                className="flex items-center gap-2 pb-6 font-light"
              >
                Drag and drop file here &shy;or click to upload
              </Text>
            </Uploader>
          </div>
          {/* ------------------------------------------------------------------------------------------------------------ */}
          {/* DISCLOSURES AND SUBMIT */}
          {/* ------------------------------------------------------------------------------------------------------------ */}
          <div className="Transparency-disclosure p-6 text-xs leading-loose">
            <Text tag="p">
              Several state election campaign laws require companies involved in
              publishing advertisements to collect information concerning
              election campaign spending. Consequently, El Toro may be required
              to provide public reporting on your campaign to comply with these
              laws. This information will only be reported or made public if
              required by law.
            </Text>
            {/* Firewall policy required by legal */}
            <Text tag="p">
              Additionally, El Toro works with a significant number of political
              candidates, independent expenditure organizations, and campaign
              committees. As such, we have a legal and ethical responsibility to
              ensure that each digital advertising campaign is delivered in
              compliance with all federal and state laws and regulations
              regarding campaign finance and coordination of expenditures. In
              order to ensure compliance with these laws and regulations, it
              will occasionally be necessary for El Toro to implement a
              “firewall” between employees, independent contractors, direct
              clients, and end-advertisers engaged in political advertising. The
              purpose of the firewall is to ensure no apparent or actual illegal
              coordination occurs between two entities who are prohibited from
              coordinating based on law, regulation, or El Toro policy. All
              end-advertisers or potential end-advertisers are required to
              notify El Toro of any conflicts or potential conflicts under
              federal or state laws related to coordination prior to submitting
              orders for services or products to El Toro.
            </Text>
          </div>
          {!isReadOnly && (
            <div className="Transparency-SubmitContainer flex flex-col items-center justify-center gap-1">
              <div className="flex items-center gap-1 pb-5">
                <Checkbox
                  disabled={isReadOnly}
                  checked={isAccuracyAcknowledged}
                  onChange={() =>
                    setUncheckedData({
                      ...uncheckedData,
                      isAccuracyAcknowledged: !isAccuracyAcknowledged,
                    })
                  }
                  label={
                    <Text className="whitespace text-sm">
                      {isAccuracyAcknowledged
                        ? 'Confirmed. All information is correct.'
                        : 'By checking this box and submitting this form, I certify, represent, and warrant to El Toro the information provided is true and accurate.'}
                    </Text>
                  }
                />
              </div>

              <div className="ButtonGroup">
                <Button
                  className={`PoliticalTransparency__submit-button mb-10 px-8 transition-all ${
                    isAccuracyAcknowledged && validForm
                      ? 'Button--primary ripples'
                      : ''
                  }`}
                  disabled={!isAccuracyAcknowledged}
                  size="xl"
                  type="submit"
                  onClick={() => {
                    if (!attemptedSubmit) setAttemptedSubmit(true)
                  }}
                >
                  {!isAccuracyAcknowledged && !validForm
                    ? 'Click on checkbox to confirm'
                    : 'Save transparency info'}
                </Button>
              </div>
            </div>
          )}
        </Form>
      </div>
    </PageContent>
  )
}
