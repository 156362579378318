import { useEffect, useRef, useState } from 'react'
import {
  Text,
  DateRangePicker,
  Fa,
  Checkbox,
  Popover,
  CalendarInput,
} from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { styleTailwind } from 'Helpers'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { ChangeType, FlightDatesEditFlexRow } from './FlightDatesEdit'
import { useAppContext, useCampaignContext } from 'Contexts'
import dayjs from 'dayjs'

// These are used in place of the table headers when the screen size is small
const TabletLabel = styleTailwind(
  'p',
  'FlightDatesOLRow__TabletLabel EditOLTable__header-text EditOLTable__TabletHeader font-bold laptopsmall:flex hidden',
)
const LabelInputPair = styleTailwind(
  'div',
  'FlightDatesOLRow__LabelInputPair EditOLRowItem__item',
)

export const FlightDatesOLRow = ({
  orderLine,
  changes,
  onChange,
  isFocusedRow,
  focusRow,
}: {
  orderLine: Campaignservicev1OrderLine
  changes?: ChangeType
  onChange: (change: ChangeType) => void
  isFocusedRow?: boolean
  focusRow: () => void
}) => {
  const [showCal, setShowCal] = useState(false)
  const [singleDay, setSingleDay] = useState(
    dayjs(orderLine.startTime).isSame(orderLine.endTime, 'day'),
  )
  const contentRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  useOnClickOutside([contentRef, buttonRef], () => setShowCal(false))
  const now = new Date()
  const { orderLines } = useCampaignContext()
  const { isLeadershipOrDev } = useAppContext()

  const styles = isFocusedRow
    ? 'FlightDatesOLRow EditOLRowItem flex relative z-[11] items-center'
    : 'FlightDatesOLRow EditOLRowItem flex relative z-0 items-center'

  const formatDate = (date: Date) =>
    date.toLocaleDateString('default', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })

  // find the earliest date non-draft order line
  // lock the user into not picking anything earlier than that date
  const campaignHasNonDraftOl = orderLines?.find(
    (ol) => ol.status !== 'ORDERLINE_STATUS_DRAFT',
  )

  const OLDates = orderLines?.map((ol) => ol.startTime)

  const earliestDate = OLDates?.sort((a, b) => {
    if (!a || !b) return 0
    return Date.parse(a.toISOString()) - Date.parse(b.toISOString())
  })[0]

  useEffect(() => {
    if (singleDay) {
      const easternTimeStart = changes?.startDate
      const easternTimeEnd = dayjs(changes?.startDate)
        .utc(true)
        .endOf('day')
        .toDate()
      onChange({
        ol: orderLine,
        startDate: easternTimeStart,
        endDate: easternTimeEnd,
      })
      return
    }
  }, [singleDay, changes?.startDate, onChange])

  return (
    <FlightDatesEditFlexRow onFocus={() => focusRow()} className={styles}>
      <LabelInputPair className="FlightDates_col1 flex-1">
        <TabletLabel>Order Line Name</TabletLabel>
        <Text>{orderLine.name}</Text>
      </LabelInputPair>
      <LabelInputPair className="FlightDates_col1 flex-1">
        <TabletLabel>Set Single Day</TabletLabel>
        <Checkbox
          label="Select this option to set the flight duration to one day"
          checked={singleDay || false}
          onChange={() => {
            setSingleDay(!singleDay)
          }}
        ></Checkbox>
      </LabelInputPair>
      <LabelInputPair className="FlightDatesEdit__dates-wrap">
        <TabletLabel>Flight Dates</TabletLabel>
        {orderLine.status === 'ORDERLINE_STATUS_DRAFT' ||
        orderLine.status === 'ORDERLINE_STATUS_REJECTED' ||
        isLeadershipOrDev ? (
          <DateRangePicker
            indefinite={singleDay}
            altToggleStyle={true}
            min={(() => {
              if (campaignHasNonDraftOl && earliestDate && !isLeadershipOrDev) {
                if (dayjs(earliestDate).isBefore(dayjs(new Date()), 'day'))
                  return new Date()
                return earliestDate
              }
              return new Date()
            })()}
            startDate={changes?.startDate || now}
            endDate={singleDay ? now : changes?.endDate || now}
            onStartDateChange={(newStartDate) => {
              onChange({ ol: orderLine, startDate: newStartDate })
            }}
            onEndDateChange={(newEndDate) => {
              onChange({ ol: orderLine, endDate: singleDay ? now : newEndDate })
            }}
          />
        ) : (
          <div className="grid w-full grid-cols-2 items-center justify-items-center text-sm">
            {orderLine.startTime && (
              <div className="flex items-center font-bold">
                <Fa icon="lock" size={1} className="text-primary !text-sm" />
                {formatDate(new Date(orderLine.startTime))}
              </div>
            )}
            <Popover
              content={
                <div
                  ref={contentRef}
                  className="bg-base is-shown min-w-[22rem]"
                >
                  <CalendarInput
                    startDate={orderLine.startTime || new Date()}
                    endDate={
                      changes?.endDate || orderLine.endTime || new Date()
                    }
                    inputTarget={'end'}
                    onInputTargetChange={() => {}}
                    onStartDateChange={() => {}}
                    onEndDateChange={(newEndDate) => {
                      if (newEndDate < new Date(orderLine.startTime || ''))
                        return
                      onChange({
                        ol: orderLine,
                        endDate: singleDay ? now : newEndDate,
                      })
                      setShowCal(false)
                    }}
                    min={orderLine.startTime || new Date()}
                  />
                </div>
              }
              isOpen={showCal}
              position={['bottom']}
            >
              <div>
                <button
                  type="button"
                  className="bg-tint-gray-50 border-tint-gray-400 flex cursor-pointer border px-2 py-1 font-bold"
                  onClick={() => setShowCal((prev) => !prev)}
                  ref={buttonRef}
                >
                  {changes?.endDate
                    ? formatDate(changes?.endDate || orderLine.endTime || '')
                    : 'No date set'}
                </button>
              </div>
            </Popover>
          </div>
        )}
      </LabelInputPair>
    </FlightDatesEditFlexRow>
  )
}
