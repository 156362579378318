import { useState, useEffect, Fragment } from 'react'
import {
  Fa,
  SelectOptionType,
  TextHeader,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
  showWarningMessage,
} from '@eltoro-ui/components'
import {
  Campaignservicev1Campaign,
  Campaignservicev1Audience,
  Campaignservicev1OrderLine,
  Targetjobservicev1Audience,
  V1AudienceType,
  V1ProductType,
} from 'next-gen-sdk'
import { useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import {
  GoBackButton,
  PolygonMap,
  RetargetingPixel,
  WebToHome,
  WarningMsg,
} from 'Components'
import { AudienceLibraryPage } from 'Pages'
import { AudienceUploadStepper } from 'Components/AudienceUploadContainer/components'
import { useAppContext, useCampaignContext } from 'Contexts'
import { getApiConfig, getJobQuotes } from 'Requests'
import { EditingCancelUpdateButtons } from '../EditingCancelUpdateButtons'
import { AudienceEditOLTable } from './AudienceEditOLTable'
import { AssetEditHeader } from '../AssetEditHeader'
import { IMPRESSION_MIN } from '../shared'
import dayjs from 'dayjs'
import {
  DetermineIfAllowedToAttachAudience,
  getHighestCPMProductType,
  poll,
  userFriendlyAudienceName,
} from 'Helpers'

export type ModifiedOrderLineType = Campaignservicev1OrderLine & {
  tempId?: string
}

export type QuoteStatusType = {
  olId?: string
  quotes?: {
    count: number
    quoteStatus?: {
      job: Targetjobservicev1Audience
      quoted?: boolean
      count?: number
    }[]
  }
}
type ExcludeIncludeType = {
  auds?: { id?: string; exclude?: boolean }[]
  olId?: string
}[]

export const AudienceEdit = ({
  className = '',
  selectedOls,
  campaign,
  onCloseModal,
  audienceMinimum = 1, // defaulting to 1 until we get requirements
  filterRules,
  setRefreshCampaign,
}: {
  className?: string
  selectedOls: Campaignservicev1OrderLine[]
  campaign: Campaignservicev1Campaign
  onCloseModal: () => void
  audienceMinimum?: number
  filterRules?: V1ProductType
  setRefreshCampaign?: (x: boolean) => void
}) => {
  const [view, setView] = useState<'library' | 'create'>()
  // prettier-ignore
  const [
    selectedAudienceType,
    setSelectedAudienceType,
  ] = useState<SelectOptionType | undefined>({
    value: 'address',
    label: 'IP Targeting',
  })
  const [modifiedOrderLines, setModifiedOrderLines] = useState<
    ModifiedOrderLineType[]
  >(selectedOls)
  // prettier-ignore
  const [includeExcludeAudiences, setIncludeExcludeAudiences] = useState<
    ExcludeIncludeType
  >([])
  const [submitting, setSubmitting] = useState(false)
  const [olQuotes, setOLQuotes] = useState<QuoteStatusType[]>()
  const { campaignServiceApi, currentOrg, roles } = useAppContext()
  const {
    setRefresh,
    isPolitical,
    orderLinePoliticalFields,
  } = useCampaignContext()
  const { pathname } = useLocation()
  const isInCampaignCreation =
    pathname.includes('/campaigns/create') ||
    pathname.includes('/campaigns/edit')

  useEffect(() => {
    const handleGetQuotes = async () => {
      if (currentOrg?.id) {
        Promise.all(
          modifiedOrderLines.map(async (modOL) => {
            if (!modOL.audiences || !currentOrg?.id)
              return { olId: modOL.id || modOL.tempId }
            const quotes = await getJobQuotes(modOL.audiences, currentOrg.id)
            return {
              olId: modOL.id || modOL.tempId,
              quotes,
            } as QuoteStatusType
          }),
        ).then((result) => setOLQuotes(result))
      }
    }
    // prevent fetching when using select buttons in library, only do this when screen
    if (view !== 'library' && view !== 'create') handleGetQuotes()
  }, [currentOrg?.id, modifiedOrderLines, view])

  // Updates the campaign order lines on submit
  // Note- this component is also on the dashboard, so it shouldn't use methods from CampaignContext,
  // unless we plan to load the campaign when opening an order line on the dashboard?
  const handleUpdate = () => {
    if (!campaignServiceApi) return
    setSubmitting(true)

    // Attach audiences to order lines
    // For each modified orderline
    Promise.all(
      modifiedOrderLines.map(async (modOL) => {
        const originalOL = selectedOls.find((ol) => ol.id === modOL.id)
        // Update existing order line
        if (originalOL && modOL.id) {
          const audiencesToAttach = modOL.audiences?.reduce(
            (acc: { id: string; exclude: boolean }[], audience) => {
              // is audience in the original OL
              if (
                !originalOL.audiences?.find(
                  (originalAud) => originalAud.id === audience.id,
                ) &&
                audience.id
              )
                return [
                  ...acc,
                  { id: audience.id, exclude: audience.exclude || false },
                ]
              return acc
            },
            [],
          )
          const audiencesToDetach = originalOL.audiences?.reduce(
            (acc: string[], audience) => {
              // is audience in the original OL but NOT on the modOL
              if (
                !modOL.audiences?.find((modAud) => modAud.id === audience.id) &&
                originalOL.audiences?.find(
                  (originalAud) => originalAud.id === audience.id,
                ) &&
                audience.id
              )
                return [...acc, audience.id]
              return acc
            },
            [],
          )

          const includeExcludeUpdates = includeExcludeAudiences
            .find(({ olId }) => olId === modOL.id)
            ?.auds?.filter((aud) => {
              // should only include the updates for audiences that were already on the order line- new ones will include exclusions above
              const isOnOriginalOl = !!originalOL.audiences?.find(
                (originalAud) => originalAud.id === aud.id,
              )
              return isOnOriginalOl
            })

          await Promise.all([
            ...((audiencesToAttach || []).length > 0
              ? [
                  campaignServiceApi.advertisingPlatformServiceBatchAddAudiences(
                    modOL.id,
                    {
                      orgId: modOL.orgId,
                      audiences: audiencesToAttach,
                    },
                  ),
                ]
              : []),
            ...((audiencesToDetach || []).length > 0
              ? [
                  campaignServiceApi.advertisingPlatformServiceBatchRemoveAudiences(
                    modOL.id,
                    {
                      orgId: modOL.orgId,
                      audienceIds: audiencesToDetach,
                    },
                  ),
                ]
              : []),
            ...((includeExcludeUpdates || []).length > 0
              ? [
                  // batch remove so the exclusion can be updated
                  campaignServiceApi
                    .advertisingPlatformServiceBatchRemoveAudiences(modOL.id, {
                      orgId: modOL.orgId,
                      audienceIds: (includeExcludeUpdates || []).reduce(
                        (acc: string[], aud) => {
                          if (!aud.id) return acc
                          return [...acc, aud.id]
                        },
                        [],
                      ),
                    })
                    .then(async (res) => {
                      if (modOL.id) {
                        // batch add with new exclusion setting
                        const updatedOl = await campaignServiceApi.advertisingPlatformServiceBatchAddAudiences(
                          modOL.id,
                          {
                            orgId: modOL.orgId,
                            audiences: includeExcludeUpdates,
                          },
                        )
                        return updatedOl
                      }
                      return res
                    })
                    .catch(() =>
                      showErrorMessage(
                        'Error updating audience exclusions',
                        '',
                      ),
                    ),
                ]
              : []),
          ])
        }
        // Create new order line
        if (modOL.tempId) {
          // create
          if (!campaignServiceApi || !currentOrg?.id) return
          const now = dayjs().add(1, 'day').startOf('day').utc(true).toDate()
          const nowPlusSeven = dayjs()
            .add(8, 'days')
            .endOf('day')
            .utc(true)
            .toDate()
          // Switch creation parameters based on isPolitical
          const orderLineCreationParameterSwitcher = isPolitical
            ? {
                name: modOL.name,
                impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
                startTime: now,
                endTime: nowPlusSeven,
                politicalFields: orderLinePoliticalFields
                  ? orderLinePoliticalFields
                  : {
                      orgZip: '',
                      submitterAddress: '',
                      submitterCity: '',
                      submitterName: '',
                      submitterState: '',
                      submitterZip: '',
                      audienceDescription: '',
                      audienceIdsTargeted: '',
                      paidForBy: '',
                    },
              }
            : {
                name: modOL.name,
                impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
                startTime: now,
                endTime: nowPlusSeven,
              }
          const createdOL = await campaignServiceApi.advertisingPlatformServiceCreateOrderLine(
            currentOrg.id,
            orderLineCreationParameterSwitcher,
            campaign.id,
          )
          if (!createdOL?.id) return

          // waiting for draft status before attaching audiences (otherwise OL gets stuck in 'creating' status)
          const newOrderLine = await poll(
            (token) =>
              campaignServiceApi.advertisingPlatformServiceGetOrderLine(
                createdOL.id || '',
                currentOrg.id ? currentOrg.id : '',
                token ? getApiConfig(token) : undefined,
              ),
            (ol?: Campaignservicev1OrderLine) =>
              ol?.status !== 'ORDERLINE_STATUS_DRAFT',
            1000,
            900000,
          )
          if (!newOrderLine?.id) return
          const audiencesToAttachToNewOL = modOL.audiences?.reduce(
            (acc: { id: string; exclude: boolean }[], audience) => {
              if (audience.id)
                return [
                  ...acc,
                  { id: audience.id, exclude: audience.exclude || false },
                ]
              return acc
            },
            [],
          )
          await campaignServiceApi.advertisingPlatformServiceBatchAddAudiences(
            newOrderLine.id,
            {
              orgId: newOrderLine.orgId,
              audiences: audiencesToAttachToNewOL,
            },
          )
        }
      }),
    )
      .then(() => {
        showSuccessMessage('Success', 'Your order line audiences were updated')
        if (setRefreshCampaign) setRefreshCampaign(true)
        if (isInCampaignCreation) setRefresh(true)
        setSubmitting(false)
        onCloseModal()
      })
      .catch(() =>
        showErrorMessage(
          'Error',
          'There was an error saving your order line audience changes',
        ),
      )
  }

  const handleAddChange = (modifiedOL: ModifiedOrderLineType) => {
    setModifiedOrderLines((prevModifiedOLs) => {
      if (modifiedOL.tempId) {
        // if it has a temp id - (is new)
        if (!modifiedOL.audiences?.length)
          // if it does not have audiences, remove it
          return prevModifiedOLs.filter((ol) => ol.tempId !== modifiedOL.tempId)

        const prevIndex = prevModifiedOLs.findIndex(
          (p) => p.tempId === modifiedOL.tempId,
        )
        // if it existed, update in place
        if (prevIndex >= 0) {
          return [
            ...prevModifiedOLs.slice(0, prevIndex),
            modifiedOL,
            ...prevModifiedOLs.slice(prevIndex + 1),
          ]
        }
        // otherwise, add it to the end
        return [...prevModifiedOLs, modifiedOL]
      }

      // update non-temp ol in place
      const prevIndex = prevModifiedOLs.findIndex((p) => p.id === modifiedOL.id)
      return [
        ...prevModifiedOLs.slice(0, prevIndex),
        modifiedOL,
        ...prevModifiedOLs.slice(prevIndex + 1),
      ]
    })
  }

  // Creates new temp order line based on campaign name
  const addNewOrderLine = (newAudience: Targetjobservicev1Audience) => {
    // add order line with tempId
    const tempOrderLineNames = [
      ...new Set(
        modifiedOrderLines.reduce((acc: string[], ol) => {
          if (ol.tempId && ol.name) return [...acc, ol.name]
          return acc
        }, []),
      ),
    ]
    let orderLineCount = campaign.orderLines?.length || 0
    if (tempOrderLineNames.length > 0) {
      orderLineCount = Number(
        tempOrderLineNames.slice(-1)[0].split('_').slice(-1),
      )
    }
    const audienceArray =
      newAudience.type === 'AUDIENCE_TYPE_VR' && newAudience.audiences
        ? newAudience.audiences
        : [newAudience]
    // add new temp ol
    const newOrderLine = {
      name: `${
        campaign?.name ? `${campaign.name}_` : ''
      }${newAudience.type?.split('_').slice(-1)}_Draft_${orderLineCount + 1}`,
      audiences: audienceArray,
      tempId: uuid(),
    }
    handleAddChange(newOrderLine)

    return newOrderLine
  }

  // Adds audience to modified order lines in state
  // If order line info given, will move audience from source to target
  // or just apply to the source ol
  const handleAddAudience = (
    newAudience: Targetjobservicev1Audience,
    moddedOLs?: {
      sourceOL?: ModifiedOrderLineType // remove from this order line
      targetOL?: ModifiedOrderLineType // target order line
    },
  ) => {
    const audienceArray =
      newAudience.type === 'AUDIENCE_TYPE_VR' &&
      newAudience.audiences &&
      newAudience.audiences.length
        ? newAudience.audiences
        : [newAudience]

    // add to all order lines
    if (!moddedOLs) {
      const emptyOrderLines = modifiedOrderLines.filter(
        (ol) => !ol.audiences || ol.audiences.length === 0,
      )
      // Determine if an OL is not a draft,
      // if draft allow attach of any audience, if not create a new one.
      // any of the modified order lines, then make a new one
      if (
        emptyOrderLines &&
        !emptyOrderLines?.length &&
        currentOrg &&
        roles &&
        modifiedOrderLines.every((ol) => {
          return !DetermineIfAllowedToAttachAudience(
            ol,
            newAudience,
            roles?.includes('nextgen_admin'),
          )
        })
      ) {
        const CreatedNewOlMessage = ({ prodType }: { prodType: string }) => {
          return (
            <>
              Attaching product type "{userFriendlyAudienceName(prodType)}" to
              is not allowed on your selection. We have created a new orderline
              for this audience.
              <br />
              <small>
                For assistance, please contact&nbsp;
                <a href="mailto:support@eltoro.com">support@eltoro.com</a> or
                your Sales Representative.
              </small>
            </>
          )
        }
        showWarningMessage(
          'Order line will be created',
          <CreatedNewOlMessage prodType={newAudience.productType || ''} />,
        )
        return addNewOrderLine(newAudience)
      }

      // attach audience to all compatible order lines
      // or add to order lines with empty audience array
      const arrayOfOlsNotAttached: string[] = []
      const arrayOfOLsAttached: string[] = []
      modifiedOrderLines.forEach((ol) => {
        const filteredAudiences = ol.audiences?.filter((existingOLAud) => {
          return !!audienceArray.find((aud) => aud.id !== existingOLAud.id)
        })
        if (!currentOrg) return

        const newAudiences = [...audienceArray]
          .reduce((acc: Campaignservicev1Audience[], currentAud) => {
            if (!filteredAudiences || filteredAudiences.length === 0) {
              return [...acc, currentAud]
            }
            if (!!acc.find((aud) => currentAud.id === aud.id)) {
              return acc
            }
            if (
              DetermineIfAllowedToAttachAudience(
                ol,
                currentAud,
                roles?.includes('nextgen_admin'),
              )
            ) {
              if (ol.name) {
                arrayOfOLsAttached.push(ol.name)
              }
              return [...acc, currentAud]
            }
            if (ol.name) {
              arrayOfOlsNotAttached.push(ol.name)
            }
            return acc
          }, [])
          .concat(filteredAudiences || [])

        const updatedOl = {
          ...ol,
          audiences: newAudiences,
        }
        if (arrayOfOlsNotAttached && arrayOfOlsNotAttached.length > 0) {
          const FailedOrSuccessfullyAddedTo = () => {
            return (
              <>
                Attaching product type "
                {userFriendlyAudienceName(newAudience.productType || '')}" is
                not allowed on the following order lines:
                <ul>
                  {arrayOfOlsNotAttached.map((c) => (
                    <li
                      className="list-item"
                      style={{ display: 'flex', paddingBottom: `${1}px` }}
                    >
                      <Fa type="solid" icon="fa-circle-dot" size={'xs'} />
                      <small>{c}</small>
                    </li>
                  ))}
                </ul>
                {arrayOfOLsAttached && arrayOfOLsAttached.length > 0 && (
                  <>
                    Audience will be attached to the following order lines:
                    <ul>
                      {arrayOfOLsAttached
                        ? arrayOfOLsAttached.map((c) => (
                            <li
                              className="list-item"
                              style={{ display: 'flex' }}
                            >
                              <Fa
                                icon="fa-circle-dot"
                                size={'xs'}
                                type="solid"
                              />
                              <small>{c}</small>
                            </li>
                          ))
                        : ''}
                    </ul>
                  </>
                )}
              </>
            )
          }

          showInfoMessage(
            'Audience unable to be attached to entire selection',
            <FailedOrSuccessfullyAddedTo />,
          )
        }
        handleAddChange(updatedOl)
      })
    }

    // moving order lines
    if (moddedOLs) {
      const { targetOL, sourceOL } = moddedOLs
      if (targetOL) {
        // add to target ol
        handleAddChange({
          ...targetOL,
          audiences: [...(targetOL?.audiences || []), ...audienceArray],
        })
      } else {
        // add to a new order line
        addNewOrderLine(newAudience)
      }
      // remove from source ol
      if (sourceOL)
        handleAddChange({
          ...sourceOL,
          audiences: sourceOL.audiences?.filter(
            (aud) => aud.id !== newAudience.id,
          ),
        })
    }
  }

  // Remove audience from the specified order line
  // if no audience given, removes ALL audiences from that order line
  const handleRemoveAudience = (
    orderLine: ModifiedOrderLineType,
    audience?: Targetjobservicev1Audience,
  ) => {
    if (!currentOrg) return
    const productCPMObject = getHighestCPMProductType(orderLine, currentOrg)

    // do not remove if last creative on non-draft OL
    if (
      orderLine.audiences &&
      orderLine.audiences.length <= 1 &&
      orderLine.status !== 'ORDERLINE_STATUS_DRAFT' &&
      !orderLine.tempId
    )
      return
    if (audience) {
      if (audience.type === 'AUDIENCE_TYPE_VR') {
        // remove sub audiences from this order line (or this VR audience)
        const newAudiences = orderLine.audiences?.filter((oldAud) => {
          return (
            !audience.audiences?.find((aud) => aud.id === oldAud.id) &&
            oldAud.id !== audience.id
          )
        })

        handleAddChange({
          ...orderLine,
          audiences: newAudiences,
        })
        return
      }
      // remove audience from this order line
      handleAddChange({
        ...orderLine,
        audiences: orderLine.audiences?.filter(
          (oldAud) => oldAud.id !== audience.id,
        ),
      })
    } else {
      // remove all audiences from order line
      handleAddChange({
        ...orderLine,
        audiences: [],
      })
    }
  }
  const handleExcludeAudience = ({
    olId,
    audiencesToIncludeOrExclude,
  }: {
    olId: string
    audiencesToIncludeOrExclude: { id: string; exclude: boolean }[]
  }) => {
    const filter = includeExcludeAudiences.filter((cur) => {
      return cur.olId !== olId
    })
    const x = [
      ...new Set([
        ...filter,
        ...[{ olId: olId, auds: audiencesToIncludeOrExclude }],
      ]),
    ].flat()
    setIncludeExcludeAudiences(x)
    // update exclude/include (for new audiences)
    const orderLine = modifiedOrderLines.find((ol) => ol.id === olId)
    const newAudiences = orderLine?.audiences?.map((aud) => {
      const change = audiencesToIncludeOrExclude.find((a) => aud.id === a.id)
      if (change) return { ...aud, exclude: change.exclude }
      return aud
    })
    if (newAudiences) handleAddChange({ ...orderLine, audiences: newAudiences })
  }

  const hasUpdates = modifiedOrderLines.some((modOL) => {
    if (modOL.tempId) return true
    const matching = selectedOls.find(
      (selectedOl) => selectedOl.id === modOL.id,
    )
    if (!matching) return true
    const originalAudiences = matching.audiences
    const modAudiences = modOL.audiences
    if (
      originalAudiences &&
      modAudiences &&
      originalAudiences.length !== modAudiences.length
    )
      return true

    return !originalAudiences?.every((originalAudience) => {
      return modAudiences?.find((modAud) => modAud.id === originalAudience.id)
    })
  })

  const selected = selectedAudienceType?.value

  const notEnoughAudiences = !isInCampaignCreation
    ? (olQuotes || [])
        .filter((ol) => (ol.quotes?.count || 0) < audienceMinimum)
        .map((quote) => {
          const ol = modifiedOrderLines.find(
            (ol) => ol.id === quote.olId || ol.tempId === quote.olId,
          )
          return { name: ol?.name, ...quote }
        })
    : []

  if (view === 'library') {
    const attachedAudiences = modifiedOrderLines
      .map((ol) => ol.audiences)
      .flat()
      ?.reduce((acc: Campaignservicev1Audience[], currentAud) => {
        if (
          !currentAud ||
          !!acc.find((audience) => audience.id === currentAud.id)
        )
          return acc
        return [...acc, currentAud]
      }, [])
    const filterMaker = () => {
      if (!filterRules) {
        return undefined
      }
      if (filterRules === 'PRODUCT_TYPE_IP_LIST_FOR_MAILING')
        return ['AUDIENCE_TYPE_ADDRESSES_FOR_IP'] as V1AudienceType[]
      if (filterRules === 'PRODUCT_TYPE_IP_TARGETING') {
        return [
          'AUDIENCE_TYPE_B2B',
          'AUDIENCE_TYPE_B2C',
          'AUDIENCE_TYPE_ZIP',
          'AUDIENCE_TYPE_ADDRESS',
          'AUDIENCE_TYPE_IP',
          'AUDIENCE_TYPE_DEVICE',
        ] as V1AudienceType[]
      }
      if (filterRules === 'PRODUCT_TYPE_VENUE_REPLAY') {
        return ['AUDIENCE_TYPE_VR'] as V1AudienceType[]
      }
      if (filterRules === 'PRODUCT_TYPE_MAP_POLYGON') {
        return ['AUDIENCE_TYPE_IPSFORGEOJSON'] as V1AudienceType[]
      }
      if (filterRules === 'PRODUCT_TYPE_NEW_MOVERS') {
        return [
          'AUDIENCE_TYPE_ESCROWMOVER',
          'AUDIENCE_TYPE_PREMOVER',
          'AUDIENCE_TYPE_POSTMOVER',
        ] as V1AudienceType[]
      }
      if (filterRules === 'PRODUCT_TYPE_DIGITAL_CANVASSING') {
        return ['AUDIENCE_TYPE_DC'] as V1AudienceType[]
      }
      if (filterRules === 'PRODUCT_TYPE_RETARGETING') {
        return ['AUDIENCE_TYPE_RETARGETING'] as V1AudienceType[]
      }
      if (filterRules === 'PRODUCT_TYPE_UNKNOWN') {
        return undefined
      }
      if (filterRules) return undefined
    }
    return (
      <div className="AudienceEdit__wrap flex items-center justify-end gap-4 py-2 pr-5">
        <GoBackButton
          onClick={() => setView(undefined)}
          position="top-right"
          classWrap={`Library--goback ${selected}`}
          className={hasUpdates ? 'ripples' : ''}
        />

        <AudienceLibraryPage
          overrideModalPreview
          selectedAudiences={attachedAudiences}
          onAddAudienceToAttach={handleAddAudience}
          onRemoveAudienceFromAttach={(audienceToRemove) =>
            modifiedOrderLines.forEach((modAud) =>
              handleRemoveAudience(modAud, audienceToRemove),
            )
          }
          filterByType={undefined}
        />
      </div>
    )
  }

  if (view === 'create') {
    const selectedTitle = selectedAudienceType?.label

    return (
      <div
        className={`AudienceEdit__upload flex flex-col py-1 ${className} AudienceEdit__upload-${selected}`}
      >
        <div className="AudienceEdit__upload-header flex items-center justify-between py-2">
          <TextHeader className="flex items-center gap-2" type={5}>
            <span>Create</span>
            <span className="text-tint-gray-500 scale-y-150">&#62;</span>
            {selectedTitle}
          </TextHeader>
        </div>
        <GoBackButton
          onClick={() => setView(undefined)}
          position="top-right"
          classWrap={`AudienceEdit__upload--goback ${selected}`}
          className={hasUpdates ? 'ripples' : ''}
        />
        {(() => {
          if (selectedAudienceType?.value === 'polygon')
            return (
              <PolygonMap
                vr={false}
                onAddAudienceToAttach={handleAddAudience}
              />
            )
          if (selectedAudienceType?.value === 'venue-replay')
            return <PolygonMap vr onAddAudienceToAttach={handleAddAudience} />
          if (selectedAudienceType?.value === 'retargeting')
            return (
              <RetargetingPixel onAddAudienceToAttach={handleAddAudience} />
            )
          if (selectedAudienceType?.value === 'web-to-home')
            return <WebToHome />
          if (selectedAudienceType?.value)
            return (
              <AudienceUploadStepper
                audienceType={selectedAudienceType.value}
                onAddAudienceToAttach={handleAddAudience}
              />
            )
        })()}
      </div>
    )
  }

  return (
    <div className={`AudienceEdit flex flex-col ${className}`}>
      <AssetEditHeader
        selectedType={selectedAudienceType}
        setSelectedType={setSelectedAudienceType}
        setView={setView}
        modifiedOrderLines={modifiedOrderLines}
        handleAddAudience={handleAddAudience}
      />
      <AudienceEditOLTable
        modifiedOrderLines={modifiedOrderLines}
        handleAddAudience={handleAddAudience}
        handleRemoveAudience={handleRemoveAudience}
        handleExcludeOrIncludeAudiences={handleExcludeAudience}
      />
      {notEnoughAudiences.length > 0 && (
        <WarningMsg>
          The following order lines do not meet the minimum audience count of{' '}
          {audienceMinimum}. Please add sufficient audiences to these order
          lines:&nbsp;
          {notEnoughAudiences.map((olQuote, i) => (
            <Fragment key={`${olQuote.olId}_${i}`}>
              <span className="font-bold">{olQuote.name}</span>
              {`${i !== notEnoughAudiences.length - 1 ? ', ' : '.'}`}
            </Fragment>
          ))}
        </WarningMsg>
      )}
      <EditingCancelUpdateButtons
        onCloseModal={onCloseModal}
        handleUpdate={handleUpdate}
        updateDisabled={
          (!hasUpdates && includeExcludeAudiences.length === 0) ||
          (notEnoughAudiences.length > 0 &&
            includeExcludeAudiences.length === 0) ||
          submitting
        }
      />
    </div>
  )
}
