import { useState } from 'react'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { styleTailwind } from 'Helpers'
import { useAppContext, useCampaignContext } from 'Contexts'
import { FlightDatesOLRow } from './FlightDatesOLRow'
import { EditingCancelUpdateButtons } from '../EditingCancelUpdateButtons'
import { showErrorMessage, showSuccessMessage } from '@eltoro-ui/components'
import dayjs from 'dayjs'

export type ChangeType = {
  ol: Campaignservicev1OrderLine
  startDate?: Date
  endDate?: Date
}
export const FlightDatesEditFlexRow = styleTailwind(
  'div',
  'FlightDatesEdit__FlexRow flex gap-2',
)
const TableHeader = styleTailwind('div', 'EditOLTable__header-text flex-1')

export const FlightDatesEdit = ({
  className = '',
  selectedOls,
  onCloseModal,
  setRefreshCampaign,
}: {
  className?: string
  selectedOls: Campaignservicev1OrderLine[]
  onCloseModal: () => void
  setRefreshCampaign?: (x: boolean) => void
}) => {
  const [focusedRow, setFocusedRow] = useState<string>()
  const [orderLineChanges, setOrderLineChanges] = useState<ChangeType[]>(
    selectedOls.map((ol) => {
      return { ol, startDate: ol.startTime, endDate: ol.endTime }
    }),
  )
  const { campaignServiceApi, currentOrg, isLeadershipOrDev } = useAppContext()
  const { setRefresh } = useCampaignContext()
  const handleUpdate = () => {
    Promise.all(
      orderLineChanges.map((change) => {
        const newChange = () => {
          return {
            startTime:
              (change.startDate &&
                change.ol.status !== 'ORDERLINE_STATUS_DEPLOYED' &&
                change.ol.status !== 'ORDERLINE_STATUS_SERVING' &&
                change.ol.status !== 'ORDERLINE_STATUS_PAUSED' &&
                change.ol.state !== 'ORDERLINE_STATE_ACTIVE') ||
              isLeadershipOrDev
                ? dayjs(change.startDate).startOf('day').utc().toDate()
                : undefined,
            endTime: change.endDate
              ? dayjs(change.endDate).endOf('day').utc().toDate()
              : undefined,
          }
        }
        if (!campaignServiceApi || !currentOrg?.id || !change.ol.id)
          return Promise.resolve({})
        return campaignServiceApi.advertisingPlatformServiceUpdateOrderLine(
          change.ol.id,
          currentOrg.id,
          { ...newChange(), orgId: currentOrg.id },
        )
      }),
    )
      .then((res) => {
        showSuccessMessage('Flight Date successfully updated', '')
        onCloseModal()
        if (setRefreshCampaign) {
          setRefreshCampaign(true)
        }
        setRefresh(true)
      })
      .catch((err) => {
        if (err) {
          showErrorMessage('Flight Date update has failed', '')
        }
      })
  }

  return (
    <div className={`FlightDatesEdit ${className}`}>
      <FlightDatesEditFlexRow className="FlightDatesEdit__header EditOLTable__header StickyHeader laptopsmall:hidden">
        <TableHeader>Order Lines</TableHeader>
        <div className="FlightDatesEdit__dates-wrap border-l-thin border-tint-gray-300 flex justify-items-center">
          <TableHeader>Start Date</TableHeader>
          <TableHeader>End Date</TableHeader>
        </div>
      </FlightDatesEditFlexRow>
      <div className="EditOLTable__body">
        {selectedOls.map((ol, i) => (
          <FlightDatesOLRow
            changes={orderLineChanges.find((c) => c.ol.id === ol.id)}
            orderLine={ol}
            key={`FlightDatesOLRow__${ol.id}_${i}`}
            isFocusedRow={focusedRow === ol.id}
            focusRow={() => setFocusedRow(ol.id)}
            onChange={(change) => {
              setOrderLineChanges((prev) =>
                prev.map((p) => {
                  if (p.ol.id === change.ol.id) {
                    return { ...p, ...change }
                  }
                  return p
                }),
              )
            }}
          />
        ))}
      </div>
      <EditingCancelUpdateButtons
        onCloseModal={onCloseModal}
        handleUpdate={handleUpdate}
        updateDisabled={!orderLineChanges.length}
      />
    </div>
  )
}
