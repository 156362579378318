import { useEffect, useState, useRef } from 'react'
import {
  SelectOptionType,
  TextHeader,
  showInfoMessage,
  showSuccessMessage,
  showErrorMessage,
} from '@eltoro-ui/components'
import {
  Campaignservicev1Campaign,
  Creativeservicev1AdType,
  Creativeservicev1Creative,
  Creativeservicev1Type,
  Campaignservicev1Creative,
  Campaignservicev1OrderLine,
} from 'next-gen-sdk'
import { useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { CreativesLibraryPage, typeGetter } from 'Pages'
import { useAppContext, useCampaignContext } from 'Contexts'
import {
  CreativeUpload,
  GoBackButton,
  AdTagCreate,
  NativeBannerCreate,
} from 'Components'
import { poll } from 'Helpers'
import { getApiConfig } from 'Requests'
import { EditingCancelUpdateButtons } from '../EditingCancelUpdateButtons'
import { ModifiedOrderLineType } from '../types'
import { AssetEditHeader } from '../AssetEditHeader'
import { CreativeEditOLTable } from './CreativeEditOLTable'
import { IMPRESSION_MIN } from '../shared'
import dayjs from 'dayjs'

export const CreativeEdit = ({
  className = '',
  selectedOls,
  campaign,
  onCloseModal,
  filterRules,
  setRefreshCampaign,
}: {
  className?: string
  selectedOls: Campaignservicev1OrderLine[]
  campaign: Campaignservicev1Campaign
  onCloseModal: () => void
  filterRules?: Creativeservicev1AdType
  setRefreshCampaign?: (x: boolean) => void
}) => {
  const [view, setView] = useState<'library' | 'create'>()
  // prettier-ignore
  const [
      selectedCreativeType,
      setSelectedCreativeType,
    ] = useState<SelectOptionType | undefined>({
      value: 'banner',
      label: 'Banner',
    })
  const [modifiedOrderLines, setModifiedOrderLines] = useState<
    ModifiedOrderLineType[]
  >(selectedOls)
  const [loadingBrandUrls, setLoadingBrandUrls] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const { currentOrg, campaignServiceApi, creativeServiceApi } = useAppContext()
  const {
    setRefresh,
    orderLinePoliticalFields,
    isPolitical,
  } = useCampaignContext()
  const { pathname } = useLocation()
  const isMounted = useRef(false)
  const isInCampaignCreation =
    pathname.includes('/campaigns/create') ||
    pathname.includes('/campaigns/edit')

  const isCompatible = (
    creativeToAdd: Creativeservicev1Creative,
    orderLine: ModifiedOrderLineType,
  ) => {
    // Note/TODO: the order line ad type is coming back as UNSPECIFIED for now, sunflower is aware
    // Will have to use ad type found on attached creatives for now
    // https://eltoroteam.slack.com/archives/C029B5WPMU3/p1671649180237709
    // if (orderLine.adType === 'ORDERLINE_ADTYPE_UNSPECIFIED') return true
    // const olCreativeType = orderLine.adType?.split('_').slice(-1)[0]
    // Creativeservicev1AdType = "CREATIVE_ADTYPE_UNSPECIFIED" | "CREATIVE_ADTYPE_BANNER" | "CREATIVE_ADTYPE_VIDEO" | "CREATIVE_ADTYPE_NATIVE" | "CREATIVE_ADTYPE_OTT"
    // Campaignservicev1AdType = "ORDERLINE_ADTYPE_UNSPECIFIED" | "ORDERLINE_ADTYPE_BANNER" | "ORDERLINE_ADTYPE_VIDEO" | "ORDERLINE_ADTYPE_NATIVE" | "ORDERLINE_ADTYPE_OTT"
    if (!orderLine.creatives || orderLine.creatives.length === 0) return true // for now, allow adding to order line with no creatives
    const olCreativeAdType = orderLine.creatives[0].adType
      ?.split('_')
      .slice(-1)[0]
    const creativeAdType = creativeToAdd.adType?.split('_').slice(-1)[0]
    return olCreativeAdType === creativeAdType
  }

  // Updates the campaign order lines on submit
  // Note- this component is also on the dashboard, so it shouldn't use methods from CampaignContext
  const handleUpdate = () => {
    setDisabled(true)
    modifiedOrderLines.forEach(async (modOL) => {
      const originalOL = selectedOls.find((ol) => ol.id === modOL.id)
      // add brandURLs to creatives
      // if has brandUrls and only 1, add the first one to the creatives
      if (modOL.brandUrlEdited && modOL.creatives) {
        // add the first brand url to all creatives
        if (modOL.brandUrls?.[0]) {
          await Promise.all(
            modOL.creatives.map(async (creative) => {
              if (!creative.id || !currentOrg?.id || !creativeServiceApi) return
              const fullCreative = await creativeServiceApi.advertisingPlatformServiceGetCreative(
                creative.id,
                currentOrg.id,
              )
              creativeServiceApi.advertisingPlatformServiceUpdateCreative(
                creative.id,
                currentOrg.id,
                {
                  nativeMetadata: {
                    alternativeMobileLandingPageUrl: modOL.brandUrls?.[0],
                  },
                },
                fullCreative.nativeMetadata !== null
                  ? 'native_metadata.alternative_mobile_landing_page_url'
                  : '',
              )
            }),
          )
        }
        if (modOL.brandUrls === undefined) {
          // remove brand url (if undefined and edited)
          await Promise.all(
            modOL.creatives.map(async (creative) => {
              if (!creative.id || !currentOrg?.id || !creativeServiceApi) return
              const fullCreative = await creativeServiceApi.advertisingPlatformServiceGetCreative(
                creative.id,
                currentOrg.id,
              )
              creativeServiceApi.advertisingPlatformServiceUpdateCreative(
                creative.id,
                currentOrg.id,
                {
                  nativeMetadata: {
                    alternativeMobileLandingPageUrl: undefined,
                  },
                },
                fullCreative.nativeMetadata !== null
                  ? 'native_metadata.alternative_mobile_landing_page_url'
                  : '',
              )
            }),
          )
        }
      }
      // update the existing order line
      if (originalOL && modOL.id) {
        const creativeIdsToAttach = modOL?.creatives
          ?.map((c) => {
            if (
              !originalOL.creatives?.find(
                (originalAud) => originalAud.id === c.id,
              )
            ) {
              return c.id || undefined
            }
            return undefined
          })
          .filter((c) => c !== undefined) as string[]
        const creativeIdsToDetach = originalOL?.creatives
          ?.map((c) => {
            if (
              !modOL.creatives?.find((modAud) => modAud.id === c.id) &&
              originalOL.creatives?.find(
                (originalAud) => originalAud.id === c.id,
              )
            ) {
              return c.id || undefined
            }
            return undefined
          })
          .filter((c) => c !== undefined) as string[]

        // Attach new creatives to existing order lines
        // Remove swapped out creatives to order lines
        try {
          if (
            creativeIdsToAttach &&
            modOL.id &&
            creativeIdsToAttach.length > 0
          ) {
            await campaignServiceApi
              ?.advertisingPlatformServiceBatchAddCreatives(modOL.id, {
                orgId: currentOrg?.id,
                creativeIds: creativeIdsToAttach,
              })
              .then((res) => {
                if (res) {
                  showSuccessMessage(
                    "Your order line's creatives were updated",
                    '',
                  )
                }
                return res
              })
          }
          if (creativeIdsToDetach && creativeIdsToDetach.length > 0) {
            await campaignServiceApi
              ?.advertisingPlatformServiceBatchRemoveCreatives(modOL.id, {
                orgId: currentOrg?.id,
                creativeIds: creativeIdsToDetach,
              })
              .then((res) => {
                if (res) {
                  showSuccessMessage(
                    "Your order line's creatives were updated",
                    '',
                  )
                }
                return res
              })
          }
          // Change clickthru if needed
          if (
            originalOL.clickThroughUrl !== modOL.clickThroughUrl &&
            originalOL.id &&
            originalOL.orgId
          ) {
            await campaignServiceApi
              ?.advertisingPlatformServiceUpdateOrderLine(
                originalOL.id,
                originalOL.orgId,
                {
                  clickThroughUrl: modOL.clickThroughUrl,
                },
              )
              .then((res) => {
                showSuccessMessage('Click-through Url updated successfully', '')
              })
              .catch((err) => {
                if (err) {
                  showErrorMessage(
                    'Click-through Url did not update correctly',
                    '',
                  )
                }
              })
          }
        } finally {
          if (isInCampaignCreation) setRefresh(true)
          if (setRefreshCampaign) {
            setRefreshCampaign(true)
          }
          onCloseModal()
        }
      }

      // if this is a new order line, create it
      if (modOL.tempId && modOL.creatives) {
        // create
        if (!campaignServiceApi || !currentOrg?.id) return
        const now = dayjs().add(1, 'day').startOf('day').utc(true).toDate()
        const nowPlusSeven = dayjs()
          .add(8, 'days')
          .endOf('day')
          .utc(true)
          .toDate()

        const orderLineCreationParameterSwitcher = isPolitical
          ? {
              name: modOL.name,
              impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
              startTime: now,
              endTime: nowPlusSeven,
              clickThroughUrl: modOL.clickThroughUrl,
              politicalFields: orderLinePoliticalFields
                ? orderLinePoliticalFields
                : {
                    orgZip: '',
                    submitterAddress: '',
                    submitterCity: '',
                    submitterName: '',
                    submitterState: '',
                    submitterZip: '',
                    audienceDescription: '',
                    audienceIdsTargeted: '',
                    paidForBy: '',
                  },
            }
          : {
              name: modOL.name,
              impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
              startTime: now,
              endTime: nowPlusSeven,
              clickThroughUrl: modOL.clickThroughUrl,
            }

        const createdOL = await campaignServiceApi.advertisingPlatformServiceCreateOrderLine(
          currentOrg.id,
          orderLineCreationParameterSwitcher,
          campaign?.id,
        )
        if (!createdOL?.id) return

        // waiting for draft status before attaching creatives (otherwise OL gets stuck in 'creating' status)
        const newOrderLine = await poll(
          (token) =>
            campaignServiceApi.advertisingPlatformServiceGetOrderLine(
              createdOL.id || '',
              currentOrg.id ? currentOrg.id : '',
              token ? getApiConfig(token) : undefined,
            ),
          (ol?: Campaignservicev1OrderLine) =>
            ol?.status !== 'ORDERLINE_STATUS_DRAFT',
          1000,
          900000,
        )
        if (!newOrderLine?.id) return

        try {
          // attach creatives
          const creativeIdsToAttach = modOL.creatives
            .map((c) => c.id || undefined)
            .filter((c) => c !== undefined) as string[]
          if (creativeIdsToAttach && creativeIdsToAttach.length > 0) {
            await campaignServiceApi
              .advertisingPlatformServiceBatchAddCreatives(newOrderLine.id, {
                orgId: currentOrg.id,
                creativeIds: creativeIdsToAttach,
              })
              .then((res) => {
                if (res && modOL?.creatives) {
                  showInfoMessage(
                    'Order Line Created',
                    `Your selected ${
                      modOL?.creatives.length > 1 ? 'creatives' : 'creative'
                    } did not have a match order line. We created one for you.`,
                  )
                }
                return res
              })
          }
        } finally {
          if (isInCampaignCreation) setRefresh(true)
          if (setRefreshCampaign) {
            setRefreshCampaign(true)
          }
          onCloseModal()
        }
      }
    })
  }

  const handleAddChange = async (modifiedOL: ModifiedOrderLineType) => {
    setModifiedOrderLines((prevModifiedOLs) => {
      if (modifiedOL.tempId) {
        // if it has a temp id - (is new)
        if (!modifiedOL.creatives?.length)
          // if it does not have creatives, remove it
          return prevModifiedOLs.filter((ol) => ol.tempId !== modifiedOL.tempId)

        const prevIndex = prevModifiedOLs.findIndex(
          (p) => p.tempId === modifiedOL.tempId,
        )
        // if it existed, update in place
        if (prevIndex >= 0) {
          return [
            ...prevModifiedOLs.slice(0, prevIndex),
            modifiedOL,
            ...prevModifiedOLs.slice(prevIndex + 1),
          ]
        }
        // otherwise, add it to the end
        return [...prevModifiedOLs, modifiedOL]
      }

      // update non-temp ol in place
      const prevIndex = prevModifiedOLs.findIndex((p) => p.id === modifiedOL.id)
      return [
        ...prevModifiedOLs.slice(0, prevIndex),
        modifiedOL,
        ...prevModifiedOLs.slice(prevIndex + 1),
      ]
    })
  }

  const handleUpdateClickThru = (
    clickThroughUrl: string,
    modifiedOL: ModifiedOrderLineType,
  ) => {
    handleAddChange({ ...modifiedOL, clickThroughUrl })
  }

  const handleAddCreative = (
    newCreative: Creativeservicev1Creative,
    orderLine?: ModifiedOrderLineType,
  ) => {
    if (
      orderLine &&
      isCompatible(newCreative, orderLine) &&
      !orderLine.creatives?.find((c) => c.id === newCreative.id)
    ) {
      // add to order line
      handleAddChange({
        ...orderLine,
        creatives: [...(orderLine.creatives || []), newCreative],
      })
      return
    }

    // Refactor'd due to weirdness with the uploader
    setModifiedOrderLines((prevModifiedOrderLines) => {
      const compatibleOrderLines = prevModifiedOrderLines.some((ol) =>
        isCompatible(newCreative, ol),
      )
      if (compatibleOrderLines) {
        // add to compatible order lines
        return prevModifiedOrderLines.map((modOl) => {
          return {
            ...modOl,
            creatives: isCompatible(newCreative, modOl)
              ? [...(modOl.creatives || []), newCreative]
              : modOl.creatives,
          }
        })
      } else {
        // if not compatible, add new order line
        const tempOrderLineNames = [
          ...new Set(
            prevModifiedOrderLines.reduce((acc: string[], ol) => {
              if (ol.tempId && ol.name) return [...acc, ol.name]
              return acc
            }, []),
          ),
        ]
        let orderLineCount = campaign.orderLines?.length || 0
        if (tempOrderLineNames.length > 0) {
          orderLineCount = Number(
            tempOrderLineNames.slice(-1)[0].split('_').slice(-1),
          )
        }
        const newOrderLine = {
          name: `${
            campaign?.name ? `${campaign.name}_` : ''
          }${newCreative.type?.split('_').slice(-1)}_Draft_${
            orderLineCount + 1
          }`,
          creatives: [newCreative],
          tempId: uuid(),
        }
        // add new order line
        return [...prevModifiedOrderLines, newOrderLine]
      }
    })
  }

  const handleRemoveCreative = (
    orderLine: ModifiedOrderLineType,
    creative?: Creativeservicev1Creative,
  ) => {
    // do not remove if last creative on non-draft OL
    if (
      orderLine.creatives &&
      orderLine.creatives.length <= 1 &&
      orderLine.status !== 'ORDERLINE_STATUS_DRAFT' &&
      !orderLine.tempId
    )
      return
    // if no creative specified, remove all creatives from order line
    if (!creative) {
      handleAddChange({ ...orderLine, creatives: [] })
    } else {
      // otherwise, remove specified creative from order line
      const filteredCreatives = orderLine.creatives?.filter(
        (c) => c.id !== creative.id,
      )
      handleAddChange({ ...orderLine, creatives: filteredCreatives })
    }
  }

  const handleSwapCreative = (
    newCreative: Creativeservicev1Creative,
    oldCreative: Creativeservicev1Creative,
    orderLine: ModifiedOrderLineType,
  ) => {
    // remove the old creative from list
    const prevIndex = orderLine.creatives?.findIndex(
      (c) => c.id === oldCreative.id,
    )
    let newCreatives: Creativeservicev1Creative[] = []
    // add new in its place
    if (prevIndex !== undefined && prevIndex >= 0) {
      newCreatives = [
        ...(orderLine.creatives || []).slice(0, prevIndex),
        newCreative,
        ...(orderLine.creatives || []).slice(prevIndex + 1),
      ]
    } else {
      newCreatives = [...(orderLine.creatives || []), newCreative]
    }
    const newOrderLine = {
      ...orderLine,
      creatives: newCreatives,
    }
    handleAddChange(newOrderLine)
  }

  useEffect(() => {
    const handlePopulateBrandUrls = async () => {
      setLoadingBrandUrls(true)
      // for each selected OL, get brand URLs for modified order lines
      const getBrandUrls = async (creatives: Campaignservicev1Creative[]) => {
        const fullCreatives = await Promise.all(
          creatives.reduce(
            (acc: Promise<Creativeservicev1Creative>[], creative) => {
              if (!creative.id || !creativeServiceApi || !currentOrg?.id)
                return acc
              return [
                ...acc,
                creativeServiceApi.advertisingPlatformServiceGetCreative(
                  creative.id,
                  currentOrg.id,
                ),
              ]
            },
            [],
          ),
        )
        const brandUrls = fullCreatives
          .filter(
            (creative) =>
              creative.nativeMetadata?.alternativeMobileLandingPageUrl !== '',
          )
          .map(
            (creative) =>
              creative.nativeMetadata?.alternativeMobileLandingPageUrl,
          )
          .filter((c) => c !== undefined)
        return [...new Set(brandUrls)]
      }
      const setBrandUrls = async () => {
        const modOls = await Promise.all(
          selectedOls.map(async (ol) => {
            if (!ol.creatives) return ol as ModifiedOrderLineType
            const brandUrls = await getBrandUrls(ol.creatives)
            return { ...ol, brandUrls } as ModifiedOrderLineType
          }),
        )
        setModifiedOrderLines(modOls)
      }

      await setBrandUrls()
    }
    if (isMounted.current) {
      handlePopulateBrandUrls().finally(() => setLoadingBrandUrls(false))
    } else {
      isMounted.current = true
    }
  }, [creativeServiceApi, currentOrg?.id, selectedOls])

  const handleUpdateBrandURL = (
    brandUrls: string[],
    modifiedOL: ModifiedOrderLineType,
  ) => {
    handleAddChange({ ...modifiedOL, brandUrls, brandUrlEdited: true })
  }

  const handleRemoveBrandURL = (modifiedOL: ModifiedOrderLineType) => {
    handleAddChange({
      ...modifiedOL,
      brandUrls: undefined,
      brandUrlEdited: true,
    })
  }

  const hasUpdates = modifiedOrderLines.some((modOL) => {
    if (modOL.tempId || modOL.brandUrlEdited) return true
    const matching = selectedOls.find(
      (selectedOl) => selectedOl.id === modOL.id,
    )
    if (!matching) return true
    // if any modOL has a different click thru than the original
    if (matching.clickThroughUrl !== modOL.clickThroughUrl) {
      return true
    }
    const originalCreatives = matching.creatives
    const modCreatives = modOL.creatives
    if (
      originalCreatives &&
      modCreatives &&
      originalCreatives.length !== modCreatives.length
    )
      return true

    return !originalCreatives?.every((originalCreative) => {
      return modCreatives?.find((modAud) => modAud.id === originalCreative.id)
    })
  })

  if (view === 'create') {
    const selectedTitle = selectedCreativeType?.label
    const selected = selectedCreativeType?.value
    return (
      <div
        className={`CreativeEdit__create flex flex-col CreativeEdit__create-${selected}`}
      >
        <div className="CreativeEdit__create-header relative flex justify-between gap-2">
          <TextHeader className="flex items-center gap-2 pt-2" type={5}>
            <span>Create</span>
            <span className="text-tint-gray-500 scale-y-150">&#62;</span>
            {selectedTitle}
          </TextHeader>
        </div>
        {selected && ['native', 'vast', 'adTag'].includes(selected) && (
          <GoBackButton
            onClick={() => setView(undefined)}
            position="top-right"
            classWrap={`CreativeEdit__create--goback ${selected}`}
            className={hasUpdates ? 'ripples' : ''}
          />
        )}
        <div className="CreativeEdit__create-wrapper">
          {(() => {
            if (
              (selected === 'video' ||
                selected === 'banner' ||
                selected === 'ott' ||
                selected === 'html5') &&
              currentOrg?.id
            )
              return (
                <CreativeUpload
                  currentOrg={currentOrg?.id}
                  onUpload={handleAddCreative}
                  onClose={() => setView(undefined)}
                  uploadType={typeGetter(selected)}
                  isPoliticalInCampaign={isPolitical && isInCampaignCreation}
                />
              )
            if (selected === 'native')
              return (
                <NativeBannerCreate handleAddCreative={handleAddCreative} />
              )
            if (selected === 'vast')
              return (
                <AdTagCreate
                  type="video"
                  handleSelect={handleAddCreative}
                  isPoliticalInCampaign={isPolitical && isInCampaignCreation}
                />
              )
            if (selected === 'adTag')
              return (
                <AdTagCreate
                  type="banner"
                  handleSelect={handleAddCreative}
                  isPoliticalInCampaign={isPolitical && isInCampaignCreation}
                />
              )
          })()}
        </div>
      </div>
    )
  }
  if (view === 'library') {
    const attachedCreatives = modifiedOrderLines
      .map((ol) => ol.creatives)
      .flat()
      ?.reduce((acc: Campaignservicev1Creative[], currentCreative) => {
        if (
          !currentCreative ||
          !!acc.find((creative) => creative.id === currentCreative.id)
        )
          return acc
        return [...acc, currentCreative]
      }, [])
    const filterMaker = () => {
      if (!filterRules) {
        return undefined
      }
      if (
        filterRules === 'CREATIVE_ADTYPE_BANNER' &&
        (!!selectedOls.find((ol) => ol.political) || isPolitical) // block from adding to political order lines
      ) {
        return [
          'CREATIVE_TYPE_BANNER',
          'CREATIVE_TYPE_AD_TAG',
        ] as Creativeservicev1Type[]
      }
      if (filterRules === 'CREATIVE_ADTYPE_BANNER') {
        return [
          'CREATIVE_TYPE_BANNER',
          'CREATIVE_TYPE_HTML5',
          'CREATIVE_TYPE_AD_TAG',
          'CREATIVE_TYPE_NATIVE_BANNER',
        ] as Creativeservicev1Type[]
      }
      if (filterRules === 'CREATIVE_ADTYPE_VIDEO') {
        return [
          'CREATIVE_TYPE_OTT',
          'CREATIVE_TYPE_VIDEO',
          'CREATIVE_TYPE_VAST_TAG',
        ] as Creativeservicev1Type[]
      }
      if (filterRules === 'CREATIVE_ADTYPE_OTT') {
        return ['CREATIVE_TYPE_OTT'] as Creativeservicev1Type[]
      }
      if (filterRules) return undefined
    }
    return (
      <>
        <div className="CreativeEdit__wrap relative flex flex-col items-center justify-end gap-4 pb-2">
          <GoBackButton
            onClick={() => setView(undefined)}
            position="top-right"
            classWrap="Library--goback"
            className={hasUpdates ? 'ripples' : ''}
          />

          <CreativesLibraryPage
            className="CreativeEdit p-0"
            selectedCreatives={attachedCreatives}
            addToCreativeSet={handleAddCreative}
            removeFromCreativeSet={(creativeId) => {
              // for each order line, remove this creative that matches the creativeId
              const fullCreative = attachedCreatives.find(
                (c) => c.id === creativeId,
              )

              if (fullCreative) {
                modifiedOrderLines.forEach((modOL) => {
                  handleRemoveCreative(modOL, fullCreative)
                })
              }
            }}
            filterByType={filterMaker()}
            modalPreview
          />
        </div>
      </>
    )
  }

  return (
    <div className={`CreativeEdit ${className}`}>
      <AssetEditHeader
        selectedType={selectedCreativeType}
        setSelectedType={setSelectedCreativeType}
        handleAddCreative={handleAddCreative}
        setView={setView}
        modifiedOrderLines={modifiedOrderLines}
      />
      <CreativeEditOLTable
        modifiedOrderLines={modifiedOrderLines}
        handleAddCreative={handleAddCreative}
        handleRemoveCreative={handleRemoveCreative}
        handleUpdateClickThru={handleUpdateClickThru}
        handleSwapCreative={handleSwapCreative}
        loadingBrandUrls={loadingBrandUrls}
        handleUpdateBrandURL={handleUpdateBrandURL}
        handleRemoveBrandURL={handleRemoveBrandURL}
        modalClose={onCloseModal}
      />

      <EditingCancelUpdateButtons
        onCloseModal={onCloseModal}
        handleUpdate={handleUpdate}
        updateDisabled={!hasUpdates || disabled}
      />
    </div>
  )
}
