import React, { useState } from 'react'
import {
  Campaignservicev1Campaign,
  Campaignservicev1OrderLine,
  V1OrderLineStatus,
  V1State,
} from 'next-gen-sdk'
import { checkOLAudienceCount, styleTailwind } from 'Helpers'
import { Button, CollapsibleContainer, Fa } from '@eltoro-ui/components'
import { OrderLineEditWrapper } from 'Components/OrderLineEditWrapper'
import { useAppContext, useCampaignContext } from 'Contexts'
import { useNavigate } from 'react-router-dom'
import { OLCard } from 'Components/OLCard'
import dayjs from 'dayjs'
// Logic for order line categories: https://eltorocorp.atlassian.net/wiki/spaces/PNG/pages/1963851865/Updated+Campaign+Creation+Flow+Business+Logic#Review-Page

type SortedOrderLinesType = {
  ready: Campaignservicev1OrderLine[]
  needs_attention: Campaignservicev1OrderLine[]
  processed: Campaignservicev1OrderLine[]
  finished: Campaignservicev1OrderLine[]
  rejected: Campaignservicev1OrderLine[]
  deployed: Campaignservicev1OrderLine[]
}

const CollapseHeaderButton = styleTailwind(
  'div',
  'w-full font-bold flex justify-between p-2 capitalize rounded focus:ring focus:outline-none hover:cursor-pointer',
)
const ContinueEditButton = styleTailwind(Button, 'ContinueEditButton')

const SectionLabel = styleTailwind('p', 'SectionLabel')

export const OrderLinesByCategory = ({
  orderLines,
  campaign,
  defaultOpen,
  onDashboard,
  setRefreshCampaign,
}: {
  orderLines: Campaignservicev1OrderLine[]
  campaign: Campaignservicev1Campaign
  defaultOpen?: string[]
  onDashboard?: boolean // For dashboard version- does not have checkboxes in 'needs attention', just edit button
  setRefreshCampaign?: (x: boolean) => void
}) => {
  const [opened, setOpened] = useState<string[]>(defaultOpen || []) // 'needs_attention' | 'processed' | 'finished'
  const {
    campaign: currentCampaign,
    launchFreshCampaign,
    submissionRemovals,
  } = useCampaignContext()
  const navigate = useNavigate()
  const { roles, isOnHold, currentOrg, isAdmin } = useAppContext()
  // for testing the other statuses (replace orderLines.reduce with fakeOrderLines.reduce)
  // const fakeOrderLines = [
  //   ...orderLines,
  //   {
  //     ...orderLines[0],
  //     status: 'ORDERLINE_STATUS_SERVING',
  //     name: 'poke',
  //     id: '12345',
  //   } as Campaignservicev1OrderLine,
  //   {
  //     ...orderLines[0],
  //     status: 'ORDERLINE_STATUS_COMPLETED',
  //     name: 'mon',
  //     id: 'blelbellbel',
  //   } as Campaignservicev1OrderLine,
  // ]

  // for testing a 'deployed' order line on the dashboard page
  // const orderLinesWithDeployed = [
  //   ...orderLines,
  //   {
  //     ...orderLines[0],
  //     status: 'ORDERLINE_STATUS_SERVING',
  //   } as Campaignservicev1OrderLine,
  // ]
  const sortedOrderLines: SortedOrderLinesType = orderLines.reduce(
    (acc: SortedOrderLinesType, ol) => {
      if (!ol.status) return acc
      if (!ol.state) return acc

      // Finished: completed, archived
      if (
        ([
          'ORDERLINE_STATUS_COMPLETED',
          'ORDERLINE_STATUS_ARCHIVED',
        ] as V1OrderLineStatus[]).includes(ol.status)
      ) {
        return { ...acc, finished: [...acc.finished, ol] }
      }

      // Deployed
      if (ol?.deployMetadata?.firstDeployTime) {
        return { ...acc, deployed: [...acc.deployed, ol] }
      }

      if (
        (['ORDERLINE_STATUS_REJECTED'] as V1OrderLineStatus[]).includes(
          ol.status,
        )
      ) {
        return { ...acc, rejected: [...acc.rejected, ol] }
      }

      // Processed: review pending, review started, approved, deploying, deployed, serving
      if (
        ([
          'ORDERLINE_STATUS_REVIEW_PENDING',
          'ORDERLINE_STATUS_REVIEW_STARTED',
          'ORDERLINE_STATUS_APPROVED',
        ] as V1OrderLineStatus[]).includes(ol.status)
      ) {
        return { ...acc, processed: [...acc.processed, ol] }
      }

      const { hasAudienceWithNoMatches } = checkOLAudienceCount(ol)

      // Look for ols that require attention
      if (
        ol.status === 'ORDERLINE_STATUS_CREATING' ||
        (ol.status === 'ORDERLINE_STATUS_DRAFT' &&
          ((ol.political && !ol.politicalFields) ||
            !ol.audiences?.length ||
            !ol.creatives?.length ||
            !ol.clickThroughUrl ||
            !ol.costRange ||
            ol.locked ||
            (ol.costRange && ol.id && submissionRemovals.includes(ol.id)) ||
            hasAudienceWithNoMatches ||
            dayjs(ol.startTime).isBefore(dayjs().utc(true).startOf('day')) ||
            ol.paid))
      ) {
        return { ...acc, needs_attention: [...acc.needs_attention, ol] }
      }

      return { ...acc, ready: [...acc.ready, ol] }
    },
    {
      ready: [],
      needs_attention: [],
      processed: [],
      finished: [],
      rejected: [],
      deployed: [],
    },
  )

  const handleSetOpen = (title: string) => {
    setOpened((prev) => {
      if (prev.includes(title)) {
        const filtered = prev.filter((section) => section !== title)
        return filtered
      } else {
        return [...prev, title]
      }
    })
  }

  const handleContinueEditing = (campaignId: string) => {
    if (currentCampaign) launchFreshCampaign({})
    const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''
    if (
      orderLines.every((ol) => ol.status !== 'ORDERLINE_STATUS_DRAFT') &&
      orderLines.some((ol) => ol.political && !ol.politicalFields)
    ) {
      navigate(`/campaigns/edit/${campaignId}/political-transparency${query}`)
      return
    }
    navigate(`/campaigns/edit/${campaignId}/settings${query}`)
  }
  return (
    <div className="OrderLinesByCategory flex flex-col gap-5">
      {onDashboard && (
        <div className="OrderLinesByCategory__edit-campaign flex items-center justify-between">
          <h5>Campaign Order Lines</h5>
          {(() => {
            if (roles?.includes('nextgen_read_only') || isOnHold) return null

            if (sortedOrderLines.rejected.length > 0)
              return (
                <Button
                  className="FixButton"
                  iconLeft={<Fa icon="arrow-right-from-bracket" size={1} />}
                  onClick={(e) => {
                    if (!e) return
                    e.stopPropagation()
                    if (campaign?.id) handleContinueEditing(campaign.id)
                  }}
                >
                  {`Fix Order Line${orderLines.length > 1 ? 's' : ''}`}
                </Button>
              )

            return (
              <ContinueEditButton
                iconLeft={<Fa icon="arrow-right-from-bracket" size={1} />}
                onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                  e.stopPropagation()
                  if (campaign?.id) handleContinueEditing(campaign.id)
                }}
              >
                {`Edit Order Line${orderLines.length > 1 ? 's' : ''}`}
              </ContinueEditButton>
            )
          })()}
        </div>
      )}
      {Object.entries(sortedOrderLines).map(([title, ols]) => {
        const thisIsOpen = opened.includes(title)
        const colors = () => {
          if (title === 'rejected') return 'bg-danger-100 !text-danger-500'
          if (title === 'needs_attention') return 'bg-warning-100 !text-warning'
          if (title === 'finished' || title === 'ready')
            return 'bg-success-50 !text-success'
          if (title === 'processed')
            return 'bg-secondary-200 !text-secondary-700'
          if (title === 'deployed') return 'bg-teal !text-white'
        }
        const icon = () => {
          if (title === 'processed') return 'thumbs-up'
          if (title === 'finished') return 'flag-checkered'
          if (title === 'rejected') return 'triangle-exclamation'
          if (title === 'ready') return 'check-double'
          if (title === 'deployed') return 'play-circle'
          return 'exclamation'
        }
        if (ols.length === 0) return null
        return (
          <React.Fragment key={title}>
            <CollapseHeaderButton
              className={colors()}
              onClick={() => handleSetOpen(title)}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === 'Enter') handleSetOpen(title)
              }}
              tabIndex={0}
            >
              <div className="flex items-center">
                <Fa icon={icon()} size={1} className={colors()} />
                {title.replace('_', ' ')} {`(${ols.length})`}{' '}
                {title === 'rejected'
                  ? ': Select an order line to make required changes and re-submit'
                  : ''}
              </div>
              <Fa
                icon={`chevron-${thisIsOpen ? 'up' : 'down'}`}
                size={1}
                className={colors()}
              />
            </CollapseHeaderButton>
            {/* only show these if on dashboard */}
            {thisIsOpen && onDashboard && title === 'needs_attention' && (
              <div className="OrderLinesByCategory__labels auto-grid-order-lines-no-checkbox text-grey-400 text-s relative flex flex-wrap items-center gap-2 pt-2 font-bold uppercase">
                <SectionLabel>Creative</SectionLabel>
                <SectionLabel>Audience</SectionLabel>
                <SectionLabel>Budget</SectionLabel>
                <SectionLabel>Impressions</SectionLabel>
                <SectionLabel>Flight Dates</SectionLabel>
                <SectionLabel>Order Line Name</SectionLabel>
              </div>
            )}
            <CollapsibleContainer
              isOpen={thisIsOpen}
              className="px-4"
              overrideHeight="auto"
            >
              {(() => {
                if (thisIsOpen) {
                  if (
                    !isAdmin &&
                    title === 'processed' &&
                    ols.some((ol) => ol.prepay || ol.political)
                  )
                    return ols?.map((currentOl, index) => {
                      return (
                        <OLCard
                          key={`title_${currentOl.id}_${index}`}
                          orderLine={currentOl}
                        />
                      )
                    })
                  if (onDashboard && title === 'needs_attention')
                    return ols?.map((currentOl, index) => {
                      return (
                        <OLCard
                          key={`title_${currentOl.id}_${index}`}
                          orderLine={currentOl}
                        />
                      )
                    })
                  return (
                    <OrderLineEditWrapper
                      setRefreshCampaign={setRefreshCampaign}
                      className="OrderLinesByCategory_editRibbon-wrap -mx-4"
                      orderLines={ols}
                      campaign={campaign}
                      removeOptions={(() => {
                        const hasInvoicedOLs = ols.some((ol) => !ol.prepay)
                        if (title === 'needs_attention') {
                          return ['add_to_checkout']
                        }
                        if (title === 'rejected') {
                          return ['remove_from_checkout']
                        }
                        if (title === 'finished')
                          return [
                            'remove_from_checkout',
                            'creatives',
                            'audiences',
                            'budget',
                            'impressions',
                            'flight_dates',
                            'creative_priority_audit',
                            'delete',
                          ]
                        if (title === 'processed' || title === 'deployed')
                          return [
                            'remove_from_checkout',
                            ...(!hasInvoicedOLs && !isAdmin ? ['budget'] : []), // <- can change on invoiced
                            ...(!hasInvoicedOLs && !isAdmin
                              ? ['impressions']
                              : []), // <- can change on invoiced
                            ...(!hasInvoicedOLs && !isAdmin
                              ? ['flight_dates']
                              : []), // <- can change END date on invoiced
                            'creative_priority_audit',
                            'delete',
                            'reSubmit',
                          ]
                      })()}
                    />
                  )
                }
                return null
              })()}
            </CollapsibleContainer>
          </React.Fragment>
        )
      })}
    </div>
  )
}
